import * as React from 'react';

function getHeight() {
    const w = window.innerWidth;
    if(w <= 767) return 550;
    if(w <= 991) return 600;
    if(w <= 1199) return 800;
    return 1000;
}

export const BannerBackgroundImage: React.FC<{ src: string }> = function ({ src }) {
    const width = Math.ceil(window.innerWidth / 50) * 50;
    const imageSrc = `https://res.cloudinary.com/rcgiltner/image/fetch/w_${width || 1200},h_${getHeight()},f_auto,c_fill,g_face/${src}`;

    return <div className="banner-img root">
        <style jsx>{`
            .root {
                background-size: cover; background-repeat: no-repeat; background-position: 50%;
                background-image: url(${imageSrc});
            }
        `}</style>
    </div>;
};
