import { useState, useEffect } from 'react';

const breakpoints = {
    xxs: 0,
    xs: 600,
    sm: 768,
    md: 992,
    lg: 1200,
}

export type Breakpoints = typeof breakpoints;
export type MediaSize = keyof Breakpoints;

function getMediaQueries(bps: Breakpoints) {
    const sizes = Object.keys(bps) as MediaSize[];
    const bpArray = sizes
        .sort((a, b) => bps[a] - bps[b])
        .map(bp => ({ name: bp, min: bps[bp] }));

    return bpArray.map((bp, i) => {
        const query = [
            i === 0 ? '' : `(min-width: ${bp.min}px)`,
            i === bpArray.length - 1 ? '' : `(max-width: ${bpArray[i + 1].min - 1}px)`
        ].filter(q => q).join(' and ');
        return { name: bp.name, query };
    });
}

const mediaQueries = getMediaQueries(breakpoints);

export function useMedia() {
    const [state, setState] = useState('xs' as MediaSize);

    useEffect(
        () => {
            // Configure all media query breakpoints
            const mediaQueryLists = mediaQueries.map(q => ({
                name: q.name,
                mediaQueryList: window.matchMedia(q.query),
            }));

            //const mediaQueryList = window.matchMedia(query);
            let active = true;

            // Extract state
            function getState() {
                return mediaQueryLists.reduce((newState, bp) => {
                    return bp.mediaQueryList.matches ? bp.name : newState;
                }, state);
            }

            // Define the listener update function
            const listener = () => {
                if(!active) {
                    return;
                }

                setState(getState());
            };

            // Attach listeners
            mediaQueryLists.forEach(q => q.mediaQueryList.addListener(listener))
            setState(getState());

            // Unmount
            return () => {
                active = false;
                mediaQueryLists.forEach(q => q.mediaQueryList.removeListener(listener));
            };
        },
        []
    );

    return state;
}