import { RxStore } from 'web-shared/lib';

export interface HeaderLink {
    name: string;
    href: string;
    isHidden?: boolean;
}

export interface UIState {
    name: string;
    pageTitle: string;

    hideLoginLinks: boolean;
    headerLinks: HeaderLink[];
}

class UIService extends RxStore<UIState> {
    constructor(initialState: UIState) {
        super(initialState, 'UI');
    }
    initialize(appName: string) {
        this.update(s => { s.name = appName; });
    }
    setPageTitle(title: string) {
        this.update(s => { s.pageTitle = title; });
    }
    setHeaderLinks(p: { links: HeaderLink[]; hideLogin: boolean }) {
        this.setState({
            hideLoginLinks: p.hideLogin,
            headerLinks: p.links,
        });
    }
}

export const ui = new UIService({
    name: '',
    pageTitle: '',

    hideLoginLinks: false,
    headerLinks: [] as HeaderLink[],
});
