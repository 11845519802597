import { shadeColor } from "../";

export function hexToCssVars(name: string, h: string) {
    return `
        --color-${name}: ${h};
        --color-${name}-light-25: ${shadeColor(20, h)};
        --color-${name}-light-50: ${shadeColor(50, h)};
        --color-${name}-light-75: ${shadeColor(75, h)};
        --color-${name}-light-90: ${shadeColor(90, h)};
        --color-${name}-dark-25: ${shadeColor(-25, h)};
        --color-${name}-dark-50: ${shadeColor(-50, h)};
        --color-${name}-dark-75: ${shadeColor(-75, h)};
        --color-${name}-dark-90: ${shadeColor(-90, h)};
    `;
}
