import ReactDOMServer from 'react-dom/server';
import { ServerStyleSheet } from "styled-components";

export function renderComponent(component: React.ReactElement) {
	const sheet = new ServerStyleSheet();
	const body = ReactDOMServer.renderToStaticMarkup(sheet.collectStyles(component));
	const styleTags = sheet.getStyleTags();
	const styles = [...document.getElementsByTagName("style")].map(s => s.innerHTML ? s.outerHTML : null).filter(s => s).join(' ') + styleTags;
	return '<!DOCTYPE html><html><head><style>body,html{background:white !important}</style>' +
		'<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">' + styles +
		'</head><body>' + body + '</body></html> ';
}

export function printHtml(html: string) {
	const iframe = document.createElement('iframe');
	iframe.style.width = '0';
	iframe.style.height = '0';
	iframe.style.position = 'absolute';
	document.body.appendChild(iframe);
	const doc = iframe?.contentDocument || iframe?.contentWindow?.document;
	if(doc) {
		doc.open();
		doc.write(html);
		doc.close();

		iframe.contentWindow?.focus();
		iframe.contentWindow?.print();
	}
	document.body.removeChild(iframe);
}

export function printComponent(component: React.ReactElement) {
	const html = renderComponent(component);
	printHtml(html);
}
