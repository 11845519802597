export function shadeColor(percent: number, from: string, to?: string): string {
    // Error check
    if(typeof (percent) !== 'number' || percent < -100 || percent > 100) {
        return from;
    }
    if(typeof (from) !== 'string' || (from[0] !== 'r' && from[0] !== '#')) {
        return from;
    }
    if((typeof (to) !== 'string' && typeof (to) !== 'undefined')) {
        return from;
    }

    const p = percent / 100.0;
    const b = p < 0;
    const r = Math.round;
    let h = from.length > 9;
    h = typeof (to) === 'string' ? to.length > 9 ? true : to === 'c' ? !h : false : h;

    const p2 = b ? p * -1 : p;
    const f = sbcRip(from);
    const t = sbcRip(to && to !== 'c' ? to : b ? '#000000' : '#FFFFFF');

    // ErrorCheck
    if(!f || !t) {
        return from;
    }

    if(h) {
        return 'rgb(' +
            r((t[0] - f[0]) * p2 + f[0]) + ',' +
            r((t[1] - f[1]) * p2 + f[1]) + ',' +
            r((t[2] - f[2]) * p2 + f[2]) +
            (f[3] < 0 && t[3] < 0 ? ')' : ',' + (f[3] > -1 && t[3] > -1 ? r(((t[3] - f[3]) * p2 + f[3]) * 10000) / 10000 : t[3] < 0 ? f[3] : t[3]) + ')');
    } else {
        return '#' + (0x100000000 + (f[3] > -1 && t[3] > -1 ? r(((t[3] - f[3]) * p2 + f[3]) * 255) : t[3] > -1 ? r(t[3] * 255) : f[3] > -1 ? r(f[3] * 255) : 255)
            * 0x1000000 + r((t[0] - f[0]) * p2 + f[0]) * 0x10000 + r((t[1] - f[1]) * p2 + f[1]) * 0x100 + r((t[2] - f[2]) * p2 + f[2])).toString(16).slice(f[3] > -1 || t[3] > -1 ? 1 : 3);
    }
}
function sbcRip(input: string) {
    const length = input.length;
    const RGB: { 0: number, 1: number, 2: number, 3: number } = { 0: 0, 1: 0, 2: 0, 3: 0 };

    if(length > 9) {
        const inputParts = input.split(',');
        if(inputParts.length < 3 || inputParts.length > 4)
            return null;
        RGB[0] = parseInt(inputParts[0].slice(4), 10);
        RGB[1] = parseInt(inputParts[1], 10);
        RGB[2] = parseInt(inputParts[2], 10);
        RGB[3] = inputParts[3] ? parseFloat(inputParts[3]) : -1;
    } else {
        let colorStr = input;
        if(length === 8 || length === 6 || length < 4)
            return null;
        if(length < 6)
            colorStr = '#' + input[1] + input[1] + input[2] + input[2] + input[3] + input[3] + (length > 4 ? input[4] + '' + input[4] : '');
        const colorHex = parseInt(colorStr.slice(1), 16);
        RGB[0] = colorHex >> 16 & 255;
        RGB[1] = colorHex >> 8 & 255;
        RGB[2] = colorHex & 255;
        RGB[3] = length === 9 || length === 5 ? Math.round(((colorHex >> 24 & 255) / 255) * 10000) / 10000 : -1;
    }
    return RGB;
}
