import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { ui } from 'web-shared/store';

interface Props extends RouteProps {
    title: string;
    componentProps?: object;
}
export const PublicRoute: React.FC<Props> = ({ children, title, component, componentProps = {}, ...rest }) => {
    return <Route {...rest} render={
        (props) => {
            const pageBaseName = ui.getStateSnapshot().name;
            ui.setPageTitle(title);
            document.title = `${pageBaseName} - ${title}`;
            return children || React.createElement(component!, { ...props, ...componentProps });
        }
    } />;
};
