import * as React from 'react';

export type SpinnerType = 'circle' | 'horizontal';
interface IProps {
    type?: SpinnerType;
    color?: string;
    center?: boolean;
    heightEm?: number;
    margin?: string | number;

    inlineText?: string;
}

const HorizontalSpinner: React.FC<IProps> = ({ color = '' }) => {
    return <span className="hollow-dots-spinner">
        <style jsx>{`
            .hollow-dots-spinner, .hollow-dots-spinner * { box-sizing: border-box; }
            .hollow-dots-spinner { height: 15px; width: calc(30px * 3); }
            .hollow-dots-spinner .dot {
                width: 15px;
                height: 15px;
                margin: 0 calc(15px / 2);
                border: calc(15px / 5) solid ${color};
                border-radius: 50%;
                float: left;
                transform: scale(0);
                animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
            }
            .hollow-dots-spinner .dot:nth-child(1) { animation-delay: calc(300ms * 1); }
            .hollow-dots-spinner .dot:nth-child(2) { animation-delay: calc(300ms * 2); }
            .hollow-dots-spinner .dot:nth-child(3) { animation-delay: calc(300ms * 3); }
            @keyframes hollow-dots-spinner-animation {
                50% { transform: scale(1); opacity: 1; }
                100% { opacity: 0; }
            }
        `}</style>

        <span className="dot" />
        <span className="dot" />
        <span className="dot" />
    </span>;
};

const CircleSpinner: React.FC<IProps> = ({ color = '' }) => {
    return <div className="half-circle-spinner">
        <style jsx>{`
            .half-circle-spinner, .half-circle-spinner * { box-sizing: border-box; }
            .half-circle-spinner {
                width: 2em;
                height: 2em;
                border-radius: 100%;
                position: relative;
            }
            .half-circle-spinner .circle {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 100%;
                border: calc(2em / 10) solid transparent;
            }
            .half-circle-spinner .circle.circle-1 {
                border-top: calc(2em / 10) solid ${color};
                animation: half-circle-spinner-animation 1s infinite;
            }
            .half-circle-spinner .circle.circle-2 {
                border-bottom: calc(2em / 10) solid ${color};
                animation: half-circle-spinner-animation 1s infinite alternate;
            }
            @keyframes half-circle-spinner-animation {
                0% {transform: rotate(0deg);}
                100%{transform: rotate(360deg);}
            }
        `}</style>

        <div className="circle circle-2"></div>
        <div className="circle circle-1"></div>
    </div>;
};

export const Spinner: React.FC<IProps> = ({ inlineText, ...props }) => {
    let Component;
    switch(props.type) {
        case 'horizontal':
            Component = HorizontalSpinner;
            break;
        default:
            Component = CircleSpinner;
            break;
    }

    return <div className="spin-container">
        <style jsx>{`
            .spin-container { display: flex; flex-direction: row; align-items: center; margin: ${props.margin}; justify-content: center; }
            .spin-container .spinner { font-size: ${props.heightEm || 1}em; }
            .spin-container .text { margin-left: 1em; }
        `}</style>

        <span className="spinner">
            <Component {...props} />
        </span>
        {inlineText ? <span className="text">{inlineText}</span> : null}
    </div>;
};
