import * as React from 'react';

interface Props {
    addonLeft: React.ReactNode;
}
export const WithAddon: React.FC<Props> = function ({ addonLeft, children }) {
    return <div className="field is-horizontal">
        <div className="field-body">
            <div className="field has-addons">
                <div className="control">
                    <a className="button is-static">{addonLeft}</a>
                </div>
                <div className="control is-expanded">
                    {children}
                </div>
            </div>
        </div>
    </div>;
};
