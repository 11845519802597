import * as React from 'react';
import { Link } from 'react-router-dom';

export const UnknownLink: React.FC<{ url: string, title?: string }> = function ({ url, children, ...p }) {
    const urlString = (url || '').toLowerCase();
    if(!urlString)
        return null;

    const link = urlString.substr(0, 4) === 'http' || url.substr(0, 1) === '#'
        ? <a href={url} {...p} target="_blank">{children}</a>
        : <Link to={url} {...p}>{children}</Link>;

    return link;
};
