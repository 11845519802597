import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import './bulma.sass';
import './core.css';

import { config } from './lib/config';
import { HotApp } from './app';
import { isDevelopment } from 'web-shared/lib/isDevelopment';
import { initializeBugsnag } from 'web-shared/lib/initialize';

if(isDevelopment) {
    // const axe = require('react-axe');
    // axe(React, ReactDOM, 1000);
}

const bugsnagClient = initializeBugsnag();
bugsnagClient.metaData = {
    client: {
        id: config.client.id,
        name: config.client.name,
    },
};
const ErrorBoundary = bugsnagClient.getPlugin('react');

ReactDOM.render(
    <ErrorBoundary>
        <Router>
            <HotApp />
        </Router>
    </ErrorBoundary>
    ,
    document.getElementById('app_root'),
);
