import * as React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { ui } from 'web-shared/store';

export const PrivateRoute: React.FC<RouteProps & { title: string, token: string | undefined, applicationType?: string }> = ({ children, title, token, component, applicationType, ...rest }) => {
    return <Route {...rest} render={
        (props) => {
            if(token) {
                const pageBaseName = ui.getStateSnapshot().name;
                document.title = `${pageBaseName} - ${title}`;
                ui.setPageTitle(title);
            }
            return token ? children || React.createElement(component!, { ...props, applicationType })
                : <Redirect to={{
                    pathname: '/login',
                    state: { from: rest.location },
                    search: window.location.search,
                }} />;
        }
    } />;
};
