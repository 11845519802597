import * as React from 'react';
import { IconNames } from './index';

interface Props {
    text: string;
    icon?: IconNames;
}
export const Tooltip: React.FC<Props> = ({ text, icon = 'info-circle' }) => {
    return <span className={`fa fa-${icon}`} title={text} />;
};
