import * as React from 'react';

interface Props {
    loading?: boolean;
    color?: string;
    size?: string;
    id?: string;
    className?: string;
}
export const ClipLoader: React.FC<Props> = function ({ loading = true, color = '#555', size = '35px', id, className }) {
    return loading ? <div id={id} className={className}>
        <style jsx>{`
            @keyframes animation-clip-loader {
                0% { transform: rotate(0deg) scale(1); }
                50% { transform: rotate(180deg) scale(0.8); }
                100% { transform: rotate(360deg) scale(1); }
            }
            .clip-loader {
                width: ${size};
                height: ${size};
                border: 2px solid;
                border-color: ${color};
                border-bottom-color: transparent;
                border-radius: 100%;
                background: transparent !important;
                animation: 0.75s linear 0s infinite normal both running animation-clip-loader;
                display: inline-block;
            }
        `}</style>
        <div className="clip-loader"></div>
    </div> : null;
};
