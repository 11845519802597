export function base64ToBlob(base64: string, mimetype: string = '', slicesize: number = 512) {
    if(!window.atob || !Uint8Array) {
        // The current browser doesn't have the atob function. Cannot continue
        return null;
    }

    var bytechars = atob(base64);
    var bytearrays = [];

    for(var offset = 0; offset < bytechars.length; offset += slicesize) {
        var slice = bytechars.slice(offset, offset + slicesize);
        var bytenums = new Array(slice.length);
        for(var i = 0; i < slice.length; i++) {
            bytenums[i] = slice.charCodeAt(i);
        }
        var bytearray = new Uint8Array(bytenums);
        bytearrays[bytearrays.length] = bytearray;
    }

    return new Blob(bytearrays, { type: mimetype });
}
