export function deepCompare(o1: any, o2: any) {
    let i, l, leftChain: any[], rightChain: any[];

    function compare2Objects(x: any, y: any) {
        let p;

        // remember that NaN === NaN returns false
        // and isNaN(undefined) returns true
        if(isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
            return true;
        }

        // Compare primitives and functions.
        // Check if both arguments link to the same object.
        // Especially useful on the step where we compare prototypes
        if(x === y) {
            return true;
        }

        // Works in case when functions are created in constructor.
        // Comparing dates is a common scenario. Another built-ins?
        // We can even handle functions passed across iframes
        if((typeof x === 'function' && typeof y === 'function') ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)) {
            return x.toString() === y.toString();
        }

        // Quick checking of one object being a subset of another.
        // todo: cache the structure of arguments[0] for performance
        for(p in y) {
            if(y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            }
            else if(typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        for(p in x) {
            if(y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            }
            else if(typeof y[p] !== typeof x[p]) {
                return false;
            }

            switch(typeof (x[p])) {
                case 'object':
                    leftChain.push(x);
                    rightChain.push(y);

                    if(!compare2Objects(x[p], y[p])) {
                        return false;
                    }

                    leftChain.pop();
                    rightChain.pop();
                    break;

                default:
                    if(x[p] !== y[p]) {
                        return false;
                    }
                    break;
            }
        }

        return true;
    }

    leftChain = []; //Todo: this can be cached
    rightChain = [];

    return !compare2Objects(o1, o2);
}