import * as React from 'react';
import { objectKeysToLowerCase } from '../lib';

const regexReplace = /<b>(\s*[^<]*\s*)<\/b>/g;

interface VariableMap {
    [name: string]: any;
}

interface Props {
    template: string;
    variables: VariableMap;
    asHtml?: boolean;
    style?: object;
}

export const TemplateString: React.FC<Props> = function ({ template = '', variables = {}, asHtml, style }) {
    const vars = objectKeysToLowerCase(variables);

    let parts = template.split(/{{\s*([a-zA-Z0-9\.]*)\s*}}/g).map((s, i) => {
        const prop = s.toLowerCase();
        const isVar = vars.hasOwnProperty(prop);
        const variableValue = isVar && (vars[prop] || '');
        const isObjectVar = isVar && variableValue && typeof (variableValue) === 'object';
        const highlight = isVar && !(isObjectVar && variableValue.hasOwnProperty('highlight') && !variableValue.highlight);

        const text = !isVar ? s :
            isObjectVar ? variableValue.text : variableValue;

        return !asHtml
            ? <span key={i} style={highlight ? style : {}}>{text}</span>
            : isVar && highlight ? '<b>' + text + '</b>' : text;
    });

    if(asHtml)
        parts = parts.join('').split(/(<b>\s*[^<]*\s*<\/b>)/g).map((s, i) => {
            const text = s.startsWith('<b>')
                ? <b style={style}>{s.replace(regexReplace, '$1')}</b>
                : <span dangerouslySetInnerHTML={{ __html: s }} />;
            return <span key={i}>{text}</span>;
        });

    return <span>{parts}</span>;
};
