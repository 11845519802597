import * as React from 'react';
import { throttle } from 'web-shared/lib';

export const useWindowScrollPosition = (throttleMs: number = 100) => {
    const [scroll, setScroll] = React.useState({
        x: window.pageXOffset,
        y: window.pageYOffset,
    });

    const handle = throttle(() => {
        setScroll({
            x: window.pageXOffset,
            y: window.pageYOffset,
        });
    }, throttleMs);

    React.useEffect(() => {
        window.addEventListener('scroll', handle);

        return () => {
            window.removeEventListener('scroll', handle);
        };
    }, []);

    return scroll;
};

export const useWindowScrolledTo = (yOffset: number) => {
    const [state, setState] = React.useState(false);

    React.useEffect(() => {
        const handle = throttle(() => {
            const isScrolled = window.pageYOffset >= yOffset;
            if(isScrolled !== state) {
                setState(isScrolled);
            }
        }, 100);

        window.addEventListener('scroll', handle);
        return () => window.removeEventListener('scroll', handle);
    }, [state]);

    return state;
};
