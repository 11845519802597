import * as React from 'react';

interface Props {
    name: string;
    date: string;
}
export const TestimonyItem: React.FC<Props> = function ({ name, date, children }) {
    return <div className="column is-4">
        <style jsx>{`
            .message-header {
                color: #fff;
                background-color: #244062;
                background-color: var(--color-secondary);
            }
            .panel { cursor: pointer; };
            .fa { font-size: 1.4em; float: left; margin-right: 5px; };
            .panel-body { color: #000; };
        `}</style>

        <div className="message">
            <div className="message-header">
                <div>
                    <div>{name}</div>
                    <div>{date}</div>
                </div>
            </div>
            <div className="message-body">
                <span className="fa fa-quote-left" />
                {children}
            </div>
        </div>
    </div>;
};
