import * as React from 'react';
import { Link } from 'react-router-dom';

import { Section, SectionHeader } from 'web-shared/components';
import { FaqItem } from './faqItem';

interface Props {
    landing?: string;
    faqs: api.LandingPageFaq[];
}
export const FaqPane: React.FC<Props> = ({ landing, faqs }) => {
    const items = faqs.length === 0
        ? <div style={{ fontStyle: 'italic', marginLeft: 15 }}>No FAQs available at this time.</div>
        : faqs.map((f, i) => <FaqItem key={i} question={f.question} answer={f.answer} />);

    return <Section id="questions" color="primary">
        <style jsx>{`
            .button { margin-left: 1em; }
        `}</style>
        <SectionHeader tag="h1" tabIndex={0}>
            Have questions? Want to learn more?
            <Link to={{ pathname: `${landing || ''}/questions` }}><button className="button is-medium">See FAQs</button></Link>
        </SectionHeader>

        <div className="columns is-multiline" style={{ marginTop: 20 }}>
            {items}
        </div>
    </Section>;
};
