import * as React from 'react';
import { classNames } from 'web-shared/lib';
import styled from 'styled-components';
import { IconNames } from 'web-shared/components';

const StyledColumn = styled.div`
    @media(max-width: 768px) { padding: 0; }
`;

interface Props {
    children: any;
    label: string;
    labelInfo?: string | JSX.Element;
    labelSize?: 'is-three-quarters' | 'is-two-thirds' | 'is-half' | 'is-one-third' | 'is-one-quarter' | 'is-full' | 'is-four-fifths' | 'is-three-fifths' | 'is-two-fifths' | 'is-one-fifth';
    tooltip?: string;
    labelInfoFull?: boolean;
    controlPaddingTopEm?: number;
    icon?: IconNames;
    inputAddonLeft?: string | JSX.Element;
    inputAddonRight?: string | JSX.Element;
    buttonRight?: string | JSX.Element;
    error?: string | null;
	className?: string;
	stack?: boolean;
	textOnly?: boolean;
}
export const SearchField: React.FC<Props> = ({
	label, labelInfo, labelSize = '', tooltip, labelInfoFull, controlPaddingTopEm = 0, className, children, icon, inputAddonLeft, inputAddonRight,
	error, buttonRight, stack = false, textOnly = false
}) => {
    let Container: keyof JSX.IntrinsicElements = 'div';
    const errors = React.Children.map(children, (c: React.ReactElement<{ 'data-error'?: string }>) => {
            if(!c)
                return '';
            if(c.type === 'input' || c.type === 'select')
                Container = 'label';
            return (c.props && c.props['data-error']) || '';
        })
        .filter(e => e);

    const mappedChildren = React.Children.map(children, (c: React.ReactElement) => {
        if(c?.type === 'select')
			return <div className="select is-fullwidth is-small">{c}</div>
        return c;
    });

    return <div>
        <style jsx>{`
            .label-input { padding-top: 0.5em; display: inline-block; }
            .label { font-weight: normal; }
            .labelInfo { font-style: italic; font-size: 0.9em; }
            .labelInfoFull { margin-top: calc(-1.5rem); margin-bottom: calc(1.5rem - 0.75rem); }
            .error :global(input) { background-color: #F2DCDB; border-color: #E6B8B7; }
            .errors { font-size: 0.9em; color: #C0504D; }
        `}</style>

        <Container className={classNames(stack ? 'my-3' : 'columns', className)}>
			<StyledColumn className={classNames(stack ? '' : 'column', { [labelSize]: !!labelSize })} >
                {icon ? <span className={classNames('fa', `fa-${icon}`)} /> : null}
                <span className={textOnly ? "" : "label-input"}>{label} {tooltip ? <span title={tooltip} className="fa fa-info-circle"></span> : null}</span>
                {labelInfo && !labelInfoFull ? <div className="labelInfo">{labelInfo}</div> : null}
            </StyledColumn>
			<StyledColumn className={stack ? '' : 'column'} style={{ marginTop: `${controlPaddingTopEm}em` }}>
                <div className={classNames({ 'is-fullwidth': true, error: errors.length > 0 })}>
                    {inputAddonLeft ?
                        <div className="field is-expanded">
                            <div className="field has-addons">
                                <span className="control">
									<a className={"button is-static is-small"}>
                                        {inputAddonLeft}
                                    </a>
                                </span>
                                <span className="control is-expanded">
                                    {mappedChildren}
                                </span>
                            </div>
                        </div>
                        : inputAddonRight ? <div className="field is-expanded">
                            <div className="field has-addons">
                                <span className="control is-expanded">
                                    {mappedChildren}
                                </span>
                                <span className="control">
                                    <a className="button is-static is-small">
                                        {inputAddonRight}
                                    </a>
                                </span>
                            </div>
                        </div> : buttonRight ? <div className="field is-expanded">
                            <div className="field has-addons">
                                <span className="control is-expanded">
                                    {mappedChildren}
                                </span>
                                <span className="control">
                                    {buttonRight}
                                </span>
                            </div>
                        </div> : mappedChildren}
                </div>
                <div className="errors">{errors.map(e => <div key={e}>{e}</div>)}</div>
                {error ? <div className="errors">{error}</div> : null}
            </StyledColumn>
        </Container>
        {labelInfo && labelInfoFull ? <div className="labelInfo labelInfoFull">{labelInfo}</div> : null}
    </div>;
};
