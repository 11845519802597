import * as React from 'react';
import scrollIntoView, { Options } from 'scroll-into-view-if-needed';
// import { dbg } from '../lib';

interface Props {
    name?: string;
    active?: boolean;
    elementType?: string;
    options?: Options;
}
export const ScrollIntoView: React.FC<Props> = (props) => {
    const {
        active = true,
        elementType = 'div',
        options = {},
        children,
    } = props;

    const nodeRef = React.useRef<Element>(undefined);
    React.useEffect(() => {
        if(active && nodeRef.current) {
            // props.name && dbg('ScrollIntoView', props.name, 'green');
            if(!options.behavior)
                options.behavior = 'smooth';
            if(!options.scrollMode)
                options.scrollMode = 'if-needed';
            if(!options.block)
                options.block = 'start';
            scrollIntoView(nodeRef.current, options);
        }
    }, [active]);

    return React.createElement(elementType, { ref: nodeRef }, children);
};
