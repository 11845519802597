import * as React from 'react';

interface Props {
    name: string;
    value: boolean;
    disabled?: boolean;
    trueText?: string;
    falseText?: string;
    showText?: boolean;
    onClick: (name: string, value: boolean) => void;
}
export const Toggle: React.FC<Props> = (props) => {
    const { name, trueText = 'On', falseText = 'Off', disabled = false, showText = true, value } = props;

    function _onClick() {
        props.onClick(name, !value);
    }

    let text = !showText ? null : value ? trueText : falseText;

    return <span>
        <style jsx>{`
            .fa-toggle-off { cursor: pointer; color: #76933c; font-size: 1.3em; position: relative; top: 2px; }
            .fa-toggle-on { cursor: pointer; color: #76933c; font-size: 1.3em; position: relative; top: 2px; }
            .disabled { cursor: not-allowed; color: #bbb; font-size: 1.3em; position: relative; top: 2px; }
        `}</style>

        <span className={(value ? 'fa fa-toggle-on' : 'fa fa-toggle-off') + (disabled ? ' disabled' : '')} onClick={_onClick} /> {text}
    </span>;
};
