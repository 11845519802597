import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { FocusOn } from 'react-focus-on';
import styled, { CSSProperties } from 'styled-components';

let iOS = false;
if(typeof navigator !== 'undefined') {
    iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
}

const StyledBackdrop = styled.div`background-color: #000; position: fixed; top: 0; bottom: 0; left: 0; right: 0; opacity: 0.8; z-index: 99; width: 100vw; height: 100vh;`;
const StyledModal = styled.div`
    position: fixed; z-index: 999; margin: 0;
    display: flex; width: 100vw; height: 100${iOS ? '%' : 'vh'};
    flex-direction: column; top: 0; left: 0;
    align-items: center;

    & > div {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100${iOS ? '%' : 'vh'};
        max-height: 100${iOS ? '%' : 'vh'};
        @media screen and (min-width: 767px) {
            padding: 2.5em;
        }
    }

    & .btn { background-color: #244062; background-color: var(--color-secondary); color: #fff; margin: 0; padding: 0 20px; border: 0; border-radius: 0; cursor: pointer; }
    & .btn:hover { background-color: #1b304a; background-color: var(--color-secondary-dark-25); }
    & .body { background-color: #fff; overflow-y: auto; flex: 1; padding: 1em; width: 100%; }
`;
const StyledHeader = styled.div`
    background-color: #244062; background-color: var(--color-secondary); color: #fff; padding: 0; width: 100%; display: flex;
    & > div { flex: 1; padding: 5px 15px; margin: 0; }
    & .btn { font-size: 1.5em; }
    & .header-title { font-size: 2em; min-height: 1em; }
    & .header-subtitle { font-style: italic; }
    @media(max-width: 768px) {
        & .header-title { font-size: 1.3em; }
        & .header-subtitle { font-size: 0.8em; }
    }
`;
const StyledFooter = styled.div`
    background-color: #244062; background-color: var(--color-secondary); color: #fff; padding: 0; width: 100%; display: flex; min-height: 50px;
    & > div { flex: 1; }
    & .btn { font-size: 1em; }
    & .footerText { float: left; padding: 14px; font-size: 1.1em; }
    & .btn-success { background-color: #4F6228; background-color: var(--color-primary); }
    & .btn-success:enabled:hover { background-color: #3b4a1e; background-color: var(--color-primary-dark-25); }
    & .btn-success:disabled { background-color: #728153; background-color: var(--color-primary-light-25); cursor: not-allowed; }
    @media(max-width: 768px) {
        & .footerText { font-size: 0.9em; }
    }
`;

interface Props {
    title: string | React.ReactElement;
    subtitle?: string;
    footerText?: string | React.ReactElement;
    okText?: string;
    cancelText?: string;
    onOk?: () => void;
    onClose?: () => void;
    isReady?: boolean;
    bodyStyle?: React.CSSProperties;
    maxWidth?: 'fit-content' | 'min-content' | 'max-content' | (string & {});
    fullHeight?: boolean;
    fullScreen?: boolean;
}
export const Modal: React.FC<Props> = (p) => {
    const { title = '', subtitle = '', okText = 'Ok', cancelText = '', footerText = '', children, onOk, onClose,
        maxWidth, fullHeight = true, bodyStyle = {}, isReady = true, fullScreen = false } = p;

    function _handleOk(e: React.MouseEvent | React.TouchEvent | Event | MouseEvent | TouchEvent) {
        e.stopPropagation();
        onOk && onOk();
    }
    function _handleClose(e: React.MouseEvent | React.TouchEvent | Event | MouseEvent | TouchEvent) {
        e.stopPropagation();
        onClose && onClose();
    }

    // Ref to hold the portal div
    const rootElemRef = React.useRef<HTMLElement>();
    if(!rootElemRef.current) {
        rootElemRef.current = document.createElement('aside');
    }

    // Hook to create add the portal div to the DOM
    React.useEffect(() => {
        const parentElem = document.body;
        // Add the detached element to the parent
        parentElem.appendChild(rootElemRef.current as Node);
        // This function is run on unmount
        return () => { rootElemRef.current?.remove(); };
    }, []);

    const style: CSSProperties = maxWidth ? { maxWidth } : {};
    if(!fullHeight)
        style.height = 'unset';
    if(fullScreen)
        style.padding = 0;

    return ReactDOM.createPortal(<FocusOn onClickOutside={_handleClose} onEscapeKey={_handleClose} shards={[]}>
        <StyledBackdrop onClick={_handleClose} />
        <StyledModal>
            <div style={style}>
                {title ? <StyledHeader>
                    <div>
                        <div className="header-title">{title}</div>
                        {subtitle ? <div className="header-subtitle">{subtitle}</div> : null}
                    </div>
                    <button className="btn fa fa-times" title="Close this dialog box." onClick={_handleClose}></button>
                </StyledHeader> : null}
                <div className="body" style={bodyStyle}>
                    {children}
                </div>
                {okText || cancelText ? <StyledFooter>
                    <div className="footerText">{footerText}</div>
                    {cancelText ? <button className="btn" onClick={_handleClose}>{cancelText}</button> : null}
                    {okText ? <button className="btn btn-success" disabled={!isReady} onClick={_handleOk}>{okText}</button> : null}
                </StyledFooter> : null}
            </div>
        </StyledModal>
    </FocusOn>, rootElemRef.current);
};
