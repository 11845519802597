import * as React from 'react';
import { shadeColor } from '../../lib';

interface Props {
    loading?: boolean;
    color?: string;
    height?: string;
    width?: string;
    id?: string;
}

export const BarLoader: React.FC<Props> = function ({ loading = true, color = '#555', width = '100%', height = '4px', id }) {
    return loading ? <div id={id} className="bar-loader">
        <style jsx>{`
            @keyframes animation-bar-loader-long {
                0% { left: -35%; right: 100%; }
                60% { left: 100%; right: -90%; }
                100% { left: 100%; right: -90%; }
            }
            @keyframes animation-bar-loader-short {
                0% { left: -200%; right: 100%; }
                60% { left: 107%; right: -8%; }
                100% { left: 107%; right: -8%; }
            }
            .bar-loader {
                position: relative;
                width: ${width};
                height: ${height};
                overflow: hidden;
                background-color: ${shadeColor(90, color)};
                background-clip: padding-box;
                border-radius: 4px;
            }
            .short, .long {
                position: absolute;
                height: ${height};
                overflow: hidden;
                background-color: ${color};
                background-clip: padding-box;
                display: block;
                border-radius: 4px;
                will-change: left, right;
                animation-fill-mode: forwards;
            }
            .long {
                animation: animation-bar-loader-long 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
            }
            .short {
                animation: animation-bar-loader-short 2.1s 1.15s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            }
        `}</style>
        <div className="long" />
        <div className="short" />
    </div> : null;
};
