import * as React from 'react';

import { Modal } from './index';

interface Props {
    name: string;
    href: string;
    onProceed: () => void;
    onClose: () => void;
}
export const ExternalLink: React.FC<Props> = ({ name, href, onClose, onProceed }) => {
    return <Modal title="Warning" okText="">
        <style jsx>{`
            .root { margin: 40px; }
            .title { margin-bottom: 30px; }
            .root p { font-size: 1.5em; margin: 20px 0; }
            .buttonContainer { margin-top: 30px; }
            .btn { margin-right: 20px; }
        `}</style>

        <div className="root">
            <h2 className="title"><i className="fa fa-warning" /> Leaving {name}...</h2>

            <p>
                You are leaving {location.hostname} and entering a website
                that {name} does not control.
            </p>

            <p>
                {name} has provided this link for your convenience, but does not endorse
                and is not responsible for the content, links, privacy policy, or security policy of
                this website.
            </p>

            <div className="buttonContainer">
                <button className="btn btn-lg btn-primary" onClick={() => onClose && onClose()}>
                    Close
                </button>
                <a href={href} target="_new" className="btn btn-lg btn-primary"
                    onClick={() => onProceed && onProceed()}>
                    Proceed
                </a>
            </div>
        </div>
    </Modal>;
};
