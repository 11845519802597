import * as React from 'react';
import { useDebounce } from 'use-debounce';
import Axios, { CancelTokenSource } from 'axios';
import { ajax, useEffectDeep } from '../lib';
import { isDevelopment } from './isDevelopment';

interface IApiConfig<P, R> {
    url: string | ((state: P) => string);
    method?: 'GET' | 'POST';
    debounce?: number;
    body: P;
    format?: (state: P) => any;
    valid?: (state: P) => boolean;
    onComplete?: (result: ResultState<R>) => void;
}
type ResultState<R> = {
    working: boolean;
    error: string | undefined;
    results: Partial<R>,
};
type Dispatch<A> = (value: A, immediate?: boolean) => void;

export function useApi<R extends Object, P extends Object = {}>(config: IApiConfig<P, R>): [ResultState<R>, typeof config.body, Dispatch<React.SetStateAction<P>>] {

    // Track requestPayload with deferred execution
    const [requestPayload, setRequestPayload] = React.useState(config.body);
    const __execute = (requestPayload as any).__execute;
    const [debouncedPayload] = useDebounce(requestPayload, __execute ? 0 : (config.debounce || 0));

    // Internal state used for tracking API progress and results
    const [state, setResultState] = React.useState({
        working: !!requestPayload,
        error: undefined as string | undefined,
        results: {} as Partial<R>,
    });

    let id = '';
    if(isDevelopment) {
        try {
            const stackLine = new Error().stack?.split('\n')[2];
            const match = /at\s+(\w+)/g.exec(stackLine || '');
            id = match?.[1] || '';
        } catch { }
    }

    const cancelTokenRef = React.useRef<CancelTokenSource>();
    useEffectDeep(
        () => {
            // dbg('useApi effect run', id, 'teal', JSON.stringify(debouncedPayload));
            if(cancelTokenRef.current) {
                cancelTokenRef.current.cancel();
            }
            cancelTokenRef.current = Axios.CancelToken.source();

            if(requestPayload) {
                // Payload is not valid so don't call the API
                if(config.valid && !config.valid(requestPayload)) {
                    return;
                }

                // Clear state before working
                if(!state.working || JSON.stringify(state.results) !== '{}')
                    setResultState({ working: true, error: undefined, results: {} });

                // Call the API
                const url = typeof (config.url) === 'string' ? config.url : config.url(requestPayload);
                ajax<R>(url, true, config.format ? config.format(requestPayload) : requestPayload, {
                    method: config.method || 'GET',
                    cancelToken: cancelTokenRef.current.token,
                }).then(response => {
                    if(response.isError === true) {
                        setResultState({ working: false, error: response.error, results: {} });
                        config.onComplete && config.onComplete({ working: false, error: response.error, results: {} });
                        return;
                    }

                    setResultState({ working: false, error: undefined, results: response.payload });
                    config.onComplete && config.onComplete({ working: false, error: undefined, results: response.payload });
                }).catch(e => {
                    if(Axios.isCancel(e)) {
                        return;
                    }
                    setResultState({ working: false, error: e, results: {} });
                    config.onComplete && config.onComplete({ working: false, error: e, results: {} });
                });
            }

            return () => {
                if(cancelTokenRef.current) {
                    cancelTokenRef.current.cancel('Canceled because of component unmounted or debounce data changed');
                }
            };
        },
        [debouncedPayload],
    );

    return [state, requestPayload, (p: React.SetStateAction<P>, executeNow?: boolean) => {
        setRequestPayload(s => {
            const newState = typeof p === 'object' ? p : (p as any)(s);
            return Object.assign(newState, { __execute: executeNow });
        });
    }];
}
