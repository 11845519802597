import * as React from 'react';
import { formatRelativeDateTime, formatUtcDateTime } from '../lib';

function getNextTimeout(ageInSeconds: number) {
    // Get current age of item
    return ageInSeconds <= 60 ? 5000 :
        ageInSeconds <= 3600 ? 30000 : 120000;
}

interface Props {
    utcTime: Date | string;
    dateOnly?: boolean;
}
export const RelativeTime: React.FC<Props> = function ({ utcTime, dateOnly }) {
    const [count, setCount] = React.useState(0);

    const ageInSeconds = (new Date().valueOf() - new Date(utcTime).valueOf()) / 1000.0;
    React.useEffect(() => {
        if(dateOnly) return;

        const next = getNextTimeout(ageInSeconds);
        const timer = next > 0 ? window.setTimeout(() => setCount(count + 1), next) : undefined;

        return () => { timer && clearTimeout(timer); };
    }, [count]);

    const relativeTime = formatRelativeDateTime(utcTime);
    return <span title={formatUtcDateTime(utcTime)}>{dateOnly && relativeTime.indexOf('hour') !== -1 ? 'the last 24 hours' : relativeTime}</span>;
};
