import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

interface LinkToObject {
	pathname: string;
	search?: string;
	hash?: string;
	state?: object;
}
interface Props {
	to: string | LinkToObject;
	replace?: boolean;
	component?: React.ComponentType<any>;
	innerRef?: React.Ref<HTMLAnchorElement>;
}

const whitelistKeys = ['_ga'];
export const Link: React.FC<Props> = ({ to, replace, component, innerRef }) => {
	const toWithQuery: LinkToObject = typeof (to) === 'string' ? { pathname: to, search: '' } : { ...to };

	// See if we have search params we want to persist
	const search = new URLSearchParams(window.location.search);
	const searchKeys = Array.from(search.keys());
	const keepKeys = searchKeys.filter(k => whitelistKeys.indexOf(k) !== -1 || k.startsWith('utm_'));

	const newSearch = new URLSearchParams();
	keepKeys.forEach(k => {
		newSearch.append(k, search.get(k));
	});
	toWithQuery.search = newSearch.toString();

	return <ReactRouterLink to={toWithQuery} replace={replace} component={component} innerRef={innerRef} />;
};
