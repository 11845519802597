import * as React from 'react';

interface Props {
    question: string;
    answer: string;
}
export const FaqItem: React.FC<Props> = function ({ question, answer }) {
    return <div className="column is-4 faqitem">
        <style jsx>{`
            .card { border-radius: 4px; }
            .icon {
                font-size: 3.5em; float: left; margin-right: 20px; margin-top: 10px;
                color: #244062;
                color: var(--color-secondary);
            }
        `}</style>

        <div className="card">
            <div className="card-content">
                <span className="fa fa-question icon" />
                <p className="title is-5">{question}</p>
                <div className="content" dangerouslySetInnerHTML={{ __html: answer }} />
            </div>
        </div>
    </div>;
};
