import * as React from 'react';
import { useFormState } from 'react-use-form-state';

import { Section, SectionHeader, Button } from 'web-shared/components';
import { auth } from 'web-shared/store';
import { useHistory } from 'react-router';
import { postJson } from 'web-shared/lib';
import { StyledInstructions } from 'web-shared/pages/instructions';

interface FormFields {
    name: string;
    newEmail: string;
    newPassword: string;
    birthDate: Date;
    referral: string;
}

export const Create: React.FC<{}> = () => {
    const history = useHistory();
    const [state, setState] = React.useState({
        busy: false,
        loggingIn: false,
        error: '',
    });
    const [form, { select, date, text, email, password, label }] = useFormState<FormFields>({}, {
        withIds: true,
        onBlur: e => e.target.classList.add('modified'),
    });
    const branchUser = auth.useState(s => s.branchUser);

    async function _submit(e: React.FormEvent) {
        e.preventDefault();

        setState(s => ({ ...s, busy: true, error: '' }));

        const postData = {
            Name: form.values.name,
            Email: form.values.newEmail,
            Password: form.values.newPassword,
            ReferralSource: form.values.referral,
            BirthDate: form.values.birthDate,
            BranchUser: branchUser,
        };

        const response = await postJson<{ id: number }>('/api/user/register', postData);

        setState(s => ({ ...s, busy: false, error: response.isError ? response.error : '' }));

        // Log the user in
        if(!response.isError && response.payload?.id) {
            setState(s => ({ ...s, loggingIn: true }));

            const loginResponse = await auth.login(form.values.newEmail, form.values.newPassword, false);

            // Clear the form
            form.clear();

            setState(s => ({
                ...s,
                loggingIn: false,
                error: 'IsError' in loginResponse && (loginResponse as any).IsError ? (loginResponse as any).ErrorMessage : '',
            }));

            // Redirect
            const { from = '/' } = history.location.state || {};
            history.replace(from);
        }
    }

    const ready = !state.busy && !state.loggingIn;
    const buttonText = state.busy ? 'Registering...'
        : (state.loggingIn ? 'Logging in...' : 'Create Login');

    return <Section fluid>
        <style jsx>{`
            .row { max-width: 350px; margin: auto; position: relative; }
            .submit { margin-top: 2em; }
        `}</style>

        <div className="row">
            <form className="form-horizontal" onSubmit={_submit}>
                <div className="field">
                    <SectionHeader size="h4" tag="h1" tabIndex={0}>Create a login</SectionHeader>
                    <StyledInstructions>
                        You will need to provide a valid email address and be able to receive/open PDF documents.
                    </StyledInstructions>
                </div>

                {state.error ? <div className="message is-danger">
                    <div className="message-body">{state.error}</div>
                </div> : null}

                <div className="field">
                    <label className="label" {...label('name')}>What is your full name?</label>
                    <div className="control">
                        <input required {...text('name')} className="input" placeholder="Jane Doe" />
                    </div>
                </div>
                <div className="field">
                    <label className="label" {...label('newEmail')}>What is your email address?</label>
                    <div className="control">
                        <input required {...email('newEmail')} className="input" placeholder="jane.doe@example.com" />
                    </div>
                </div>
                <div className="field">
                    <label className="label" {...label('newPassword')}>Select a password.</label>
                    <div className="control">
                        <input required {...password('newPassword')} className="input" />
                    </div>
                </div>
                <div className="field">
                    <label className="label" {...label('birthDate')}>When is your birth date?</label>
                    <div className="control">
                        <input required {...date('birthDate')} className="input" placeholder="mm/dd/yyyy" />
                    </div>
                </div>
                <div className="field">
                    <label className="label" {...label('referral')}>How did you hear about us?</label>
                    <div className="select is-fullwidth">
                        <select required {...select('referral')}>
                            <option value="">Select...</option>
                            <option value="Billboard">Billboard</option>
                            <option value="Email">Email</option>
                            <option value="Facebook / Social Media">Facebook / Social Media</option>
                            <option value="Financial Institution Website">Financial Institution Website</option>
                            <option value="From one of our Banking Professionals">From one of our Banking Professionals</option>
                            <option value="Internet Search">Internet Search</option>
                            <option value="Mail">Mail</option>
							<option value="Mobile Banking">Mobile Banking</option>
                            <option value="Online Ad">Online Ad</option>
							<option value="Online Banking">Online Banking</option>
                            <option value="Other">Other</option>
                            <option value="Post-it Note">Post-it Note</option>
                            <option value="Signage or screen outside our office">Signage or screen outside our office</option>
                            <option value="Signage or screen within our office">Signage or screen within our office</option>
                            <option value="Word of Mouth">Word of Mouth</option>
                        </select>
                    </div>

                </div>
                <div className="field submit">
                    <StyledInstructions>
                        You will receive an email asking you to confirm your email registration and documenting you
                        can receive, open and read attached PDF disclosures.
                    </StyledInstructions>
                    <Button fullWidth type="submit" disabled={!ready} size="large" color="secondary">
                        {buttonText}
                    </Button>
                </div>
            </form>
        </div>
    </Section>;
};
