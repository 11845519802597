export function chunk<T>(arr: T[], len: number) {
    const chunks = [];
    const n = arr.length;
    let i = 0;

    while(i < n) {
        // tslint:disable-next-line: no-array-mutation
        chunks.push(arr.slice(i, i += len));
    }

    return chunks;
}
