import * as React from 'react';
import { classNames } from '../lib';
import { IconNames } from './icon';

interface Props {
    color?: 'primary' | 'secondary' | 'highlight' | 'danger' | 'white' | 'light' | 'dark' | 'black' | 'text' | 'success' | 'warning' | 'danger';
    size?: 'small' | 'normal' | 'medium' | 'large';
    fullWidth?: boolean;
    type?: 'submit' | 'button';
    name?: string;
    disabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    borderColor?: string;
    flex?: boolean;
    icon?: IconNames;
    title?: string;
    loading?: boolean;
    className?: string;
    noBtnClass?: boolean;
    error?: boolean;
}

export const Button: React.FC<Props> = function ({
    color, size = 'normal', type = 'button', error, name, disabled, fullWidth, children, onClick, borderColor, flex, icon, title, loading, className, noBtnClass
}) {
    let btnStyle: React.CSSProperties = {};
    let colorClass;
    switch(color || '') {
        case 'primary':
        case 'danger':
        case 'secondary':
        case 'highlight':
            colorClass = `btn-${color}`;
            break;
        case '':
            break;
        default:
            colorClass = `is-${color || 'light'}` + ` btn-${color || 'light'}`
            break;
    }
    if(borderColor) {
        btnStyle.borderColor = borderColor;
    }
    if(flex) {
        btnStyle.height = 'auto';
    }

    return <button className={classNames(className, noBtnClass ? '' : 'btn', 'button', `is-${size}`, colorClass, { 'validation-error': error, 'is-loading': loading, 'is-fullwidth': fullWidth })}
        onClick={(e) => onClick && onClick(e)}
        style={btnStyle} type={type} disabled={disabled || loading} name={name}
        title={title}>
        <style jsx>{`
            .fa { margin-right: 0.5em; }
            .btn-primary {
                background-color: #4F6228;
                background-color: var(--color-primary);
                border-color: #3b4a1e;
                border-color: var(--color-primary-dark-25);
                color: #fff;
            }
            .btn-primary:hover {
                background-color: #3B4A1E;
                background-color: var(--color-primary-dark-25);
                border-color: #3b4a1e;
                border-color: var(--color-primary-dark-25);
                color: #fff;
            }
            .btn-secondary {
                background-color: #244062;
                background-color: var(--color-secondary);
                border-color: #1b304a;
                border-color: var(--color-secondary-dark-25);
                color: #fff;
            }
            .btn-secondary:hover {
                background-color: #1B304A;
                background-color: var(--color-secondary-dark-25);
                border-color: #1b304a;
                border-color: var(--color-secondary-dark-25);
                color: #fff;
            }
            .btn-highlight {
                background-color: #e26b0a;
                background-color: var(--color-highlight);
                border-color: #aa5008;
                border-color: var(--color-highlight-dark-25);
                color: #fff;
            }
            .btn-highlight:hover {
                background-color: #aa5008;
                background-color: var(--color-highlight-dark-25);
                border-color: #aa5008;
                border-color: var(--color-highlight-dark-25);
                color: #fff;
            }
            .btn-danger {
                background-color: #c0504d; border-color: #b44340; color: #fff;
            }
            .btn-danger:hover {
                background-color: #a13c39; border-color: #873230;
            }
            .validation-error {
                border-left: 2px solid #c0504d;
            }
        `}</style>
        {icon ? <span className={`fa fa-${icon}`} /> : null}
        {children}
    </button>;
};
