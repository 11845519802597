import * as React from 'react';

interface Props {
    type: string;
    autoFocus?: boolean;
    isValid: boolean;
}
export const Input: React.FC<Props> = (props) => {
    const { isValid, ...p } = props;
    const showValidation = isValid !== undefined;
    const iconClass = 'fa fa-' + (isValid === true ? 'check' : 'times');

    const className = !showValidation ? ''
        : (isValid === true ? 'input-group has-success' : 'input-group has-error');

    const addon = !showValidation ? null :
        <div className={`input-group-addon ${isValid === true ? 'valid ': 'invalid'}`}>
            <span className={iconClass} style={{ width: 20 }} />
        </div>;

    return <div style={{ position: 'relative' }}>
        <style jsx>{`
            .valid { background-color: #EBF1DE; color: #4F6228; }
            .invalid { background-color: #F2DCDB; color: #632523; }
            .tooltip { position: absolute; opacity: 0.8; left: 101%; width: 200px; height: 2em; top: 4px; margin: auto; }
        `}</style>

        <div className={className}>
            <input {...p} />
            {addon}
        </div>

        {
            isValid === true || isValid === undefined || !isValid ? null :
                <div className="tooltip right" role="tooltip">
                    <div className="tooltip-arrow" />
                    <div className="tooltip-inner" style={{ textAlign: 'left' }}>
                        {isValid}
                    </div>
                </div>
        }

    </div>;
};
