import * as React from 'react';
import styled from 'styled-components';
import { classNames, formatUtcDate } from 'web-shared/lib';
import { RelativeTime } from '.';

const Container = styled.div`
	& .relative { font-style: italic; color: #999; font-weight: normal !important; }
  	& .time-line {
		position: relative;
		margin: 0 0 0px 0;
		padding: 0;
		list-style: none;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: -10px;
			width: 2px;
			background: var(--color-secondary-light-50);
			left: 32px;
			border-radius: 2px;
		}
    	> .time-label {
      		> span {
				font-weight: 600;
				padding: 5px 10px;
				display: inline-block;
				background-color: #efefef;
				border-radius: 2px;
				font-size: 14px;
	      	}
    	}

    	> li {
      		position: relative;
      		margin-bottom: 10px;
      		margin-right: 0;

      		> .time-line-item {
				display: flex;
				-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
				box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
				border-radius: 3px;
				background: #fff;
				color: #444;
				margin-right: 15px;
				position: relative;
				margin-left: 50px;
				transition: 0.25s;

				&.hover { background-color: #d3d8c9; }

        		> .time-line-header, .annotation { font-size: 14px; padding: 6px 10px; }
        		.annotation { font-size: 12px; color: #999; }

        		.time-line-header {
					margin: 0;
					color: #555;
					line-height: 1.1;
					flex: 1;
				}
			}
			> .fa {
				position: absolute;
				border-radius: 50%;
				width: 10px;
				height: 10px;
				color: #666;
				background: #ecf0f1;
				left: 28px;
				top: 9px;
				border: 2px solid var(--color-secondary-light-50);
				display: inline-block;
				box-sizing: border-box;
			}
		}
  	}
`;

function getFormattedData(items: api.ApplicationDetailEvent[], format = "p") {
	const activities: Record<string, ActivityItem[]> = {};
	items.forEach(({ logTimeUtc, summary, userId, userIp }, index) => {
		const dateStr = formatUtcDate(logTimeUtc, "M/d/yyyy");
		const list = activities[dateStr] || [];
		list.push({
			time: formatUtcDate(logTimeUtc, format),
			text: summary,
			key: index,
			userId,
			userIp,
		});
		activities[dateStr] = list;
	});
	return activities;
}

type ActivityItem = {
	time: string;
	text: string;
	key: number;
	userId?: number;
	userIp?: string;
}
interface Props {
	items: api.ApplicationDetailEvent[];
	format?: string;
	users?: api.UserDetails[];
}
export const Timeline: React.FC<Props> = ({ items, format, users }) => {
	const activities = getFormattedData(items, format);
	const dates = Object.keys(activities);
	const [hoverUserId, setHoverUserId] = React.useState(0)
	const [hoverIp, setHoverIp] = React.useState('')

	function onHoverUser(userId: number, hovering: boolean) {
		setHoverUserId(hovering ? userId : 0);
	}
	function onHoverIp(ip: string, hovering: boolean) {
		setHoverIp(hovering ? ip : '');
	}

	return <Container className="time-line-ctnr">
		{dates.map(d => {
			return <ul className="time-line" key={d}>
				<li className="time-label">
					<span>{d}</span> <span className="relative"><RelativeTime utcTime={d} dateOnly /></span>
				</li>
				{activities[d].map(({ time, text, key, userId, userIp }) => {
					const user = users?.find(u => u.id === userId);
					return <TimlineItem time={time} text={text} key={key} userId={userId}
						user={user?.fullName || ''} userIp={userIp}
						hover={hoverUserId == userId || hoverIp == userIp} onHoverUser={onHoverUser}
						onHoverIp={onHoverIp} email={user?.email}
					/>;
				})}
			</ul>
		})}
	</Container>;
}

interface ItemProps {
	time: string;
	text: string;
	user?: string;
	userId?: number;
	userIp?: string;
	email?: string;
	hover?: boolean;
	onHoverUser?: (userId: number, hovering: boolean) => void;
	onHoverIp?: (ip: string, hovering: boolean) => void;
}
const TimlineItem: React.FC<ItemProps> = ({ time, text, user, userId, userIp, hover, onHoverUser, onHoverIp, email }) => {
	return <li>
		<i className="fa" />
		<div className={classNames('time-line-item', { hover })}>
			<span className="annotation">
				<i className="fa fa-clock-o" /> {time}
			</span>
			<div className="time-line-header">{text}</div>
			{userId ? <span className="annotation" title={`User ID: ${userId}\nEmail: ${email}`}
				onMouseOver={() => onHoverUser?.(userId, true)}
				onMouseOut={() => onHoverUser?.(userId, false)}
			><i className="fa fa-user-o" /> {user}</span> : null}
			{userIp ? <span className="annotation"
				onMouseOver={() => onHoverIp?.(userIp, true)}
				onMouseOut={() => onHoverIp?.(userIp, false)}
			><i className="fa fa-map-marker" /> {userIp}</span> : null}
		</div>
	</li>;
}
