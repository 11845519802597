import * as React from 'react';

import { Header as Header1 } from './option1';
import { Header as Header2 } from './option2';
import { Header as Header3 } from './option3';
import { HeaderLink } from 'web-shared/store';

export interface HeaderProps {
    links?: HeaderLink[];
}

interface Props {
    option: 1 | 2 | 3;
}

export const Header: React.FC<HeaderProps & Props> = ({ option, ...p }) => {
    switch(option) {
        default:
        case 1:
            return <Header1 {...p} />;
        case 2:
            return <Header2 {...p} />;
        case 3:
            return <Header3 {...p} />;
    }
};
