import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { HtmlString } from 'web-shared/components';
import { BannerBackgroundImage } from 'web-frontend/components';
import { shadeColor } from 'web-shared/lib';
import Color from 'ac-colors';
import { config } from 'web-frontend/lib';

const Styles = styled.div`
    .pre { white-space: pre-line; }
    .button {
        margin: 20px 1em; height: auto;
    }
    .button-title { font-weight: bold; font-size: 1.3em; text-decoration: underline; }
`;

function useLink(url: string | undefined) {
    return url && (url === '#' || url.substr(0, 4) === 'http');
}

interface Props {
    showButton?: boolean;
    banner: api.LandingPageBanner;
    productNameOnly?: boolean;
}
export const Banner: React.FC<Props> = function ({ showButton = false, banner, productNameOnly }) {
    // Get the requested banner
    const highlightColor = banner.highlightColor || '#76933C';
    const boxOpacity = banner.boxOpacity === null ? 0.7 : banner.boxOpacity;
    const backgroundColor = 'rgba(0, 0, 0, ' + boxOpacity + ')';

    // Get button configuration
    const buttons: api.SiteConfigBannerButton[] = banner.buttons?.length
        ? banner.buttons.map(b => ({
            color: b.color || banner.highlightColor,
            linkUrl: (b.linkUrl || '').toLowerCase(),
            text: b.text,
            details: b.details,
            textColor: b.textColor,
        }))
        : [{
            color: banner.highlightColor,
            linkUrl: (banner.linkUrl || '').toLowerCase(),
            text: banner.buttonText,
        }];
    const imageUrl = banner.imageUrl || '';

    return <Styles className="text-center" style={{ position: 'relative', overflow: 'hidden' }}>
        <style jsx>{`
            .text {
                position: absolute; bottom: 0; left: 0; right: 0; color: #fff; font-size: 2.8em; margin: 0 auto; margin-bottom: 100px;
                box-shadow: 0 0 50px 50px rgba(0, 0, 0, 0.7);
                background-color: rgba(0, 0, 0, 0.7); padding: 10px;
                background-color: ${backgroundColor};
                box-shadow: ${banner.boxShading ? '0 0 50px 50px ' + backgroundColor : null};
                font-size: 2.4em;
                text-align: center;
            }
            @media (max-width: 599px) {
                .text { font-size: 1.5em; }
            }
        `}</style>

        <BannerBackgroundImage src={imageUrl.indexOf('https://') === 0 ? imageUrl : `https://rcg.blob.core.windows.net/images/${imageUrl}`} />
        <div className="text">
            <HtmlString text={productNameOnly ? `<b>${config.site.name}</b>` : banner.bannerText} style={{ color: highlightColor, fontWeight: 'bold' }} />
            {
                !showButton ? null : buttons.filter(b => b.linkUrl).map((b, i) => {
                    const color = b.color || highlightColor;
                    const contrastRatio = Color.contrastRatio(new Color({ color, type: 'hex' }), new Color({ color: [255, 255, 255] }));
                    const style = {
                        color: b.textColor || (contrastRatio < 3 ? '#000' : '#fff'),
                        backgroundColor: color,
                        borderColor: shadeColor(-12, color),
                        margin: '1em',
                    };
                    const content = <div>
                        <div className={b.details ? 'button-title' : ''}>{b.text}</div>
                        <div className="pre" dangerouslySetInnerHTML={{ __html: b.details || '' }} />
                    </div>;

                    return useLink(b.linkUrl)
                        ? <a key={i} className="button is-medium" href={b.linkUrl} style={style}>{content}</a>
                        : <Link key={i} to={b.linkUrl} className="button is-medium" style={style}>{content}</Link>;
                })
            }
        </div>
    </Styles>;
};
