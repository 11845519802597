import * as React from 'react';
import { Link } from 'react-router-dom';

import { auth, ui } from 'web-shared/store';
import { config } from 'web-frontend/lib';
import { UnknownLink } from './unknownLink';
import { AccountLink } from './accountLink';
import { useWindowScrollPosition } from 'web-shared/lib';

interface NavItemProps {
    name: string | React.ReactElement;
    href: string;
    title?: string;
}
const NavItem: React.FC<NavItemProps> = function ({ name, href, title }) {
    return <li title={title} className="navbar-item">
        <UnknownLink url={href}>{name}</UnknownLink>
    </li>;
};

const headerScrollHeight = 200;

// Main application navbar
export const Header: React.FC<{}> = function () {
    const { userName, name } = auth.useState(s => ({
        userName: s.userName,
        name: s.name,
    }));
    const { headerLinks, hideLoginLinks } = ui.useState(s => ({
        headerLinks: s.headerLinks,
        hideLoginLinks: s.hideLoginLinks,
    }));

    const { y } = useWindowScrollPosition(50);

    const siteLogo = <Link to="/" className="navbar-item">
        <img alt={config.site.name} src={config.site.logo} className="brandImg" />
    </Link>;
    const clientLogo = <a href={config.client.url} target="_blank" className="navbar-item">
        <img alt={config.client.name} src={config.client.logo} className="brandImg" />
    </a>;

    const opacity = Math.max(0.4, Math.min(y / headerScrollHeight, 1));
    return <header className="navbar">
        <style jsx global>{`
            .main { margin-top: 50px; background-color: #efefef; }
            #header { margin-top: -50px; }
            .navbar {
                min-height: 50px;
                background-color: rgba(36, 64, 98, ${opacity});
                position: fixed;
                top: 0; left: 0; right: 0; height: 50px;
                color: #555;
                z-index: 99;
            }
            .navbar-item img { height: 40px; max-height: 40px; }
            .navbar-brand {
                display: flex;
                align-items: center;
                background-color: #fff;
                width: auto;
                position: absolute;
                margin-left: 2em;
                padding: 0.75em 1.5em;
            }
            .navbar-brand .navbar-item { padding: 0; }
            .navbar-brand > *:not(:first-child) { margin-left: 10px; }
            .by { display: inline-block; }

            .navbar-brand .rounded-container {
                position: absolute; overflow: hidden;
                margin: 0;
                left: 0; top: 99%;
                width: 100%; height: 15px;
            }

            .rounded {
                position: absolute;
                border-bottom: 1px solid #ddd;
                left: 50%; bottom: 0;
                width: 300%; height: 500px;
                background: #fff;
                border-radius: 50%;
                margin-left: -150%;
            }

            .navbar-end .navbar-item { padding: 0; }
            .navbar-end .navbar-item a {
                padding: 0 0.75rem; height: 100%; display: flex; align-items: center;
                font-size: 1.2em; color: #fff;
            }
            .navbar-end .navbar-item:hover a {
                background-color: #506681;
                background-color: var(--color-secondary-light-25); color: #fff;
                text-shadow: none;
            }
        `}</style>
        <div className="navbar-brand">
            {
                config.hideClientLogo ? siteLogo :
                    config.clientLogoFirst
                        ? <>{clientLogo}{siteLogo}</>
                        : <>{siteLogo}<div className="by">by</div>{clientLogo}</>
            }
            <span className="rounded-container">
                <span className="rounded"></span>
            </span>
        </div>
        <div className="navbar-menu is-active">
            <ul className="navbar-end">
                {headerLinks.map(v => v.isHidden ? null : <NavItem key={v.href} name={v.name} href={v.href} />)}
                {userName ? <AccountLink key="account" name={name} email={userName} /> : null}
                {userName ? <NavItem key="logoff" name={<span className="fa fa-sign-out" style={{ fontSize: '1.3em' }} />} href="/logoff" title="Log off" /> : null}
                {!userName && !hideLoginLinks ? <NavItem key="create" name="Register" href="/create" /> : null}
                {!userName && !hideLoginLinks ? <NavItem key="login" name="Login" href="/login" /> : null}
            </ul>
        </div>
    </header>;
};
