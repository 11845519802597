import * as React from 'react';
import { Bugsnag } from "@bugsnag/js/types";
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

export const envConfig = getConfigFromElement('env_config') as api.EnvConfig;

let bugsnagClient: Bugsnag.Client;
export function initializeBugsnag() {
    if(bugsnagClient)
        return bugsnagClient;

    // Configure Bugsnag error reporting
    bugsnagClient = bugsnag({
        apiKey: envConfig?.apiKey || 'test',
        releaseStage: envConfig?.env || 'local',
    });

    bugsnagClient.use(bugsnagReact, React);
    return bugsnagClient;
}

export function getConfigFromElement(elementId: string) {
    // Read the JSON-formatted data from the DOM.
    const element = document.getElementById(elementId);
    const json = element?.textContent || element?.innerText; // fallback for IE ≤ 8

    if(element)
        element.innerHTML = '';

    if(!json)
        return {};

    return JSON.parse(json);
}
