import * as React from 'react';
import { Create } from './create';
import { LoginForm } from 'web-shared/pages/login';

const LoginRegisterPage: React.FC = () => {
    let hasLoggedInBefore = false;

    try {
        if(localStorage['successful-login'] === 'true')
            hasLoggedInBefore = true;
    } catch(e) {}

    return <div className="container" style={{ marginTop: 40 }}>
        <style jsx>{`
            .column.divider { flex-grow: 0; background-color: #ddd; padding: 0; }
            .column.divider div { width: 2px; }
        `}</style>

        <div className="columns">
            <div className="column">
                {hasLoggedInBefore ? <LoginForm title="Already registered?" showOnlineBankingNote /> : <Create />}
            </div>
            <div className="column divider">
                <div></div>
            </div>
            <div className="column">
                {hasLoggedInBefore ? <Create /> : <LoginForm title="Already registered?" showOnlineBankingNote />}
            </div>
        </div>
    </div>;
};

export default LoginRegisterPage;
