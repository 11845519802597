import * as React from 'react';
import { Modal, Section, SectionHeader, Spinner, Button } from 'web-shared/components';
import { auth } from 'web-shared/store';

interface Props {
    onClose: () => void;
}

export const BranchLoginForm: React.FC<Props> = function ({ onClose }) {
    const [{ busy, error }, setState] = React.useState({
        busy: false,
        error: '',
    });
    const userNameRef = React.useRef<HTMLInputElement>(null);
    const passwordRef = React.useRef<HTMLInputElement>(null);

    function _modalClose() {
        onClose && onClose();
    }

    async function _login(e: React.FormEvent) {
        e.preventDefault();

        setState({ busy: true, error: '' });

        try {
            const response = await auth.branchLogin(userNameRef.current?.value || '', passwordRef.current?.value || '');

            setState({ busy: false, error: response.errorMessage || '' });
            if(!response.errorMessage) {
                onClose && onClose();
            }
        } catch(e) {
            setState({ busy: false, error: 'Unable to contact the login server.' });
        }
    }

    return <Modal title="Branch Login" okText="" onClose={_modalClose}>
        <style jsx>{`
            .instructions { margin-bottom: 20px; font-style: italic; color: #777; font-size: 1.2em; }
        `}</style>

        <Section>
            <div className="row" style={{ maxWidth: 400, margin: 'auto', padding: '0 20px', position: 'relative', color: '#555' }}>
                <form className="form-horizontal" onSubmit={_login}>
                    <div className="form-group" style={{ marginTop: 40 }}>
                        <SectionHeader size="h4">Branch employee login!</SectionHeader>
                        <h5 className="instructions">
                            Please enter your employee email address and password below.
                        </h5>
                    </div>
                    {error ? <div className="message is-danger">
                        <div className="message-body">{error}</div>
                    </div> : null}
                    <div className="field">
                        <label htmlFor="branchLoginName">Employee Email Address:</label>
                        <input type="text" ref={userNameRef} className="input" id="branchLoginName" />
                    </div>
                    <div className="field">
                        <label htmlFor="branchPassword">Employee Password:</label>
                        <input type="password" ref={passwordRef} className="input" id="branchPassword" />
                    </div>
                    <div className="field">
                        <Button type="submit" disabled={busy} color="primary" size="medium" fullWidth>
                            {busy ? <Spinner type="horizontal" /> : 'Login'}
                        </Button>
                    </div>
                    <div>
                        <a href="/forgot" className="button is-light" style={{ width: '100%' }}>Forgot My Password</a>
                    </div>
                </form>
            </div>
        </Section>
    </Modal>;
};
