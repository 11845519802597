import * as React from 'react';
import { Link } from 'react-router-dom';

import { auth, ui } from 'web-shared/store';
import { config } from 'web-frontend/lib';
import { UnknownLink } from './unknownLink';
import { AccountLink } from './accountLink';
import styled from 'styled-components';

const StyledDiv = styled.div`
    svg .color-primary { fill: ${config.colors.logo1}; }
    svg .color-secondary { fill: ${config.colors.logo2}; }
`;
const NavbarEnd = styled.ul`
    & .navbar-item { padding: 0; }
    & .navbar-item a { padding: 0 0.75rem; color: #333; height: 100%; display: flex; align-items: center; }
    & .navbar-item:hover a {
        background-color: #244062;
        background-color: var(--color-secondary);
        color: #fff;
    }
    & .sign-out-text { display: none; }
    & .fa-sign-out { font-size: 1.3em; }
    @media(max-width: 768px) {
        & .sign-out { display: flex; align-items: center; }
        & .sign-out-text { display: inline; padding-left: 10px; }
        & .fa-sign-out { font-size: 2.5em; }
    }
`;

interface NavItemProps {
    name: string | React.ReactElement;
    href: string;
    title?: string;
}
const NavItem: React.FC<NavItemProps> = function ({ name, href, title }) {
    return <li title={title} className="navbar-item">
        <UnknownLink url={href} title={title}>{name}</UnknownLink>
    </li>;
};

function img(src: string = '', alt: string) {
    if(src.startsWith('<svg')) {
        return <div dangerouslySetInnerHTML={{ __html: src }} />
    }
    return <img alt={alt} src={src} className="brandImg" />;
}

// Main application navbar
export const Header: React.FC<{}> = () => {
    const { userName, name } = auth.useState(s => ({
        userName: s.userName,
        name: s.name,
    }));
    const { headerLinks, hideLoginLinks } = ui.useState(s => ({
        headerLinks: s.headerLinks,
        hideLoginLinks: s.hideLoginLinks,
    }));

    const siteLogo = <Link to="/"><span className="navbar-item">
        {img(config.site.logo, config.site.name)}
    </span></Link>;
    const clientLogo = <a href={config.client.url} target="_blank" className="navbar-item">
        {img(config.client.logo, config.client.name)}
    </a>;

    return <header className="navbar">
        <style jsx global>{`
            .navbar { min-height: 50px; }
            .navbar-item img { max-height: 100%; height: 40px; }
            .navbar-end { display: flex; }
            li.navbar-item { display: inline-block; }
        `}</style>
        <StyledDiv className="navbar-brand">
            {config.hideClientLogo ? siteLogo :
                config.clientLogoFirst ? <>{clientLogo}{siteLogo}</> :
                    <>{siteLogo}{clientLogo}</>}
        </StyledDiv>
        <div className="navbar-menu is-active">
            <NavbarEnd className="navbar-end">
                {headerLinks.map(v => v.isHidden ? null : <NavItem key={v.href} name={v.name} href={v.href} />)}
                {userName ? <AccountLink key="account" name={name} email={userName} /> : null}
                {userName ? <NavItem key="logoff" name={<span className="sign-out"><span className="fa fa-sign-out" /><span className="sign-out-text">Sign Out</span></span>} href="/logoff" title="Log off" /> : null}
                {!userName && !hideLoginLinks ? <NavItem key="create" name="Register" href="/create" /> : null}
                {!userName && !hideLoginLinks ? <NavItem key="login" name="Login" href="/login" title="Log out" /> : null}
            </NavbarEnd>
        </div>
    </header>;
};
