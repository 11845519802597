import * as React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import { Banner } from 'web-frontend/components';
import { classNames, useWindowSize } from 'web-shared/lib';

const Styles = styled.div`
    color: #fff; background: #fff; position: relative; top: 0;

    .banner { height: calc(100vh - 50px); margin-bottom: 2em; padding-top: 0; }
    .banner.small, .banner.small .banner-img { height: 450px; }
    .slick-slide { visibility: hidden; }
    .slick-active { visibility: visible; }

    .slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div, .slick-slide > div > div, .banner-img { height: 100%; }

    .text-center { margin: 0; padding: 0; }
    .hint { position: absolute; bottom: 0; width: 100%; height: 0.5em; }
    .hintLine {
        width: 100%; height: 100%;
        background-color: #244062;
        background-color: var(--color-secondary);
    }
    .hintCircle { left: 50%; width: 5em; height: 5em; position: absolute; top: 0; }
    .hintCircle2 {
        width: 5em; height: 5em; border-radius: 5em; position: absolute; top: -2.3em; left: -50%;
        background-color: #244062;
        background-color: var(--color-secondary);
    }
    .hintCircle3 {
        width: 4em; height: 4em; border-radius: 4em; margin-left: .5em; margin-top: .5em;
        background-color: #92a0b1;
        background-color: var(--color-secondary-light-50);
    }
    .hintIcon { font-size: 3em; margin-left: 0.17em; margin-top: 0.15em; display: inline-block; }
`;
interface Props {
    banners: api.LandingPageBanner[];
    small?: boolean;
}
export const HeaderPane: React.FC<Props> = ({ banners, small = false }) => {
    useWindowSize(); // Just needed to recalc when window size changes
    const headerEl = document.getElementsByClassName('navbar');
    const headerHeight = headerEl[0]?.clientHeight || 50;

    function _buildBanner() {
        // No banners defined. Do something default
        if(!banners || banners.length === 0)
            return null;

        const bannerDivs = banners.map((b, i) => <Banner key={i} banner={b} showButton={!small} productNameOnly={small} />);
        const settings = {
            dots: banners.length > 1,
            arrows: banners.length > 1,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 9000,
            fade: true,
            swipe: false,
            draggable: false,
        };
        return <Slider {...settings}>{bannerDivs}</Slider>;
    }

    const banner = _buildBanner();
    return <Styles id="header" className="header">
        <div className={classNames('banner', { small })} style={{ height: small ? '450px' : `calc(100vh - ${headerHeight}px)` }}>
            {banner}
        </div>

        <div className="hint">
            <div className="hintLine" />
            <div className="hintCircle">
                <div className="hintCircle2">
                    <div className="hintCircle3">
                        <span className="fa fa-chevron-down hintIcon" />
                    </div>
                </div>
            </div>
        </div>
    </Styles>;
};
