import * as React from 'react';
import { Icon, IconNames, Spinner } from './index';
import { classNames } from 'web-shared/lib';

interface Props {
    title?: string | React.ReactNode;
    subTitle?: string;
    icon?: IconNames;
    marginEm?: number;
    spinner?: 'circle' | 'horizontal';
    color?: string;
    colorSubTitle?: string;
    fullPage?: boolean;
    sizeEm?: number;
    pre?: boolean;
}

export const NonIdealState: React.FC<Props> = (props) => {
    const {
        children, title, subTitle, icon, marginEm, spinner, sizeEm = 1, pre,
        color = '#555', fullPage,
        colorSubTitle = '#777',
    } = props;

    return <div className={classNames('root', { fullPage })}>
        <style jsx>{`
            .root {
                font-size: ${sizeEm}em;
                text-align: center;
                margin: ${marginEm || 2}em;
                padding: 0.25em;
                color: ${color};
                white-space: ${pre ? 'pre-wrap' : 'normal'}
            }
            .root-title { font-size: 2em; margin-top: 1em; }
            .root-subTitle {
                margin: 0 auto;
                max-width: 350;
                font-style: italic;
                color: ${colorSubTitle};
                font-size: 1.2em;
            }
            .icon {
                margin: 1em;
            }
            .fullPage { margin-top: calc(50vh - 200px); }
        `}</style>

        {icon === '' ? null : <div className="icon">
            {spinner ? <Spinner type={spinner} color={color} heightEm={2.5} /> :
                <Icon sizeEm={6} name={icon || 'lightbulb-o'} />}
        </div>}

        {!title ? null : <div className="root-title">{title}</div>}
        {subTitle ? <div className="root-subTitle">{subTitle}</div> : null}
        {children}
    </div>;
};
