import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormState } from 'react-use-form-state';

import { Spinner, Section, SectionHeader, Button } from 'web-shared/components';
import { auth } from 'web-shared/store';
import { StyledInstructions } from './instructions';

interface FormFields {
    userName: string;
    password: string;
    remember: boolean;
}
interface Props {
    title: string;
    skipRedirect?: boolean;
    showOnlineBankingNote?: boolean;
}
export const LoginForm: React.FC<Props> = ({ title, skipRedirect, showOnlineBankingNote }) => {
    const history = useHistory();

    // See if the remember option was checked
    let remember_default = false;
    try {
        remember_default = localStorage.getItem('rememberLogin') === 'true';
    } catch(err) { }

    const [state, setState] = React.useState({
        busy: false,
        error: '',
    });

    const [formState, { checkbox, email, password, label }] = useFormState<FormFields>({
        remember: remember_default,
    }, {
        withIds: true,
        onBlur: e => e.target.classList.add('modified'),
    });

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        const { userName, password, remember } = formState.values;

        setState(() => ({ busy: true, error: '' }));

        try {
            const response = await auth.login(userName, password, remember);

            if(response.isError) {
                setState({ busy: false, error: response.errorMessage });
                if(response.expiredUrl) {
                    history.replace(response.expiredUrl);
                }
                return;
            }

            if(!skipRedirect) {
                setState({ busy: false, error: '' });

                // Redirect
                const { from, search } = history.location.state || {};
                if(search)
                    from.search = search;
                history.replace(from || '/');
            }
        } catch(ex) {
            const error = 'Unable to contact the login server.';
            setState({ busy: false, error: error });
        }
    }

    return <Section fluid>
        <style jsx>{`
            .root { max-width: 350px; margin: auto; position: relative; }
        `}</style>

        <div className="row root">
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="field">
                    <SectionHeader size="h4" tag="h1" tabIndex={0}>{title || 'Welcome Back!'}</SectionHeader>
                    <StyledInstructions>
                        Please enter your email address and password below. If you can't remember your password, select the "I forgot my password" button below.
                    </StyledInstructions>
                    {showOnlineBankingNote ? <StyledInstructions>
                        (please note: this is <b>not your online banking login and password</b>)
                    </StyledInstructions> : null}
                </div>

                {state.error ? <div className="message is-danger">
                    <div className="message-body">{state.error}</div>
                </div> : null}

                <div className="field">
                    <label className="label" {...label('userName')}>Email Address:</label>
                    <div className="control">
                        <input autoFocus required {...email('userName')} className="input" />
                    </div>
                </div>
                <div className="field">
                    <label className="label" {...label('password')}>Password:</label>
                    <div className="control">
                        <input required {...password('password')} className="input" />
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <label className="label" {...label('remember')}>
                            <input {...checkbox('remember')} /> Keep me logged in?
                        </label>
                    </div>
                </div>
                <div className="field">
                    <Button type="submit" disabled={state.busy} size="medium" color="secondary" fullWidth>
                        {state.busy ? <Spinner type="horizontal" /> : 'Login'}
                    </Button>
                </div>

                <div className="field">
                    <Link to="/forgot">
                        <Button disabled={state.busy} color="white" fullWidth>
                            I forgot my password
                        </Button>
                    </Link>
                </div>
            </form>
        </div>
    </Section>;
};
