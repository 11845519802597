import * as React from 'react';
import { RouteChildrenProps } from 'react-router-dom';

import { auth } from 'web-shared/store';

const LogoffPage: React.FC<RouteChildrenProps> = () => {
    React.useEffect(() => {
        if(!history) {
            throw new Error('Router component not found!');
        }

        auth.logoff();

        // Return to the previous page
        history.back();
    });

    return null;
};

export default LogoffPage;
