import {
    clearLoginData, getTokenStorage, getToken, getLocalStorage, postForm,
    RxStore, clearBranchLoginData, getBranchUserToken, LoginData, setBranchLoginData, setLoginData, getLoginData, gaLogin,
} from 'web-shared/lib';
import { Bugsnag } from '@bugsnag/js';

export type AuthCallback = (isAuthenticated: boolean) => void;
export type TokenResponseError = {
    error: string;
    error_description: string;
    error_uri?: string;
};
export interface LoginErrorResponse {
    isError: true;
    errorMessage: string;
    expiredUrl?: string;
}

type AuthState = {
    admin: boolean;
    globalAdmin: boolean;
    viewDemographics: boolean;
    name: string;
    userId: number;
    clientId: number;
    userName: string;
    expires?: string;
    token?: string;
    emailVerified: boolean;
    lenderAssisted?: boolean;

    branchUser: string;
    branchLoginModalOpen: boolean;
    ui: {
        actions: {};
    };
};

interface UpdateUserParams { email?: string; name?: string; verified?: boolean; }

class AuthService extends RxStore<AuthState> {
    private onAuthChangeCallback?: AuthCallback;
    private bugsnagClient?: Bugsnag.Client;

    constructor(initialState: AuthState) {
        super(initialState, 'Auth');
    }
    initialize(bugsnagClient: Bugsnag.Client | undefined, onAuthChange?: AuthCallback) {
        this.bugsnagClient = bugsnagClient;
        this.onAuthChangeCallback = onAuthChange;

        // Check for user auth
        this.update(s => {
            const loginData = getLoginData();
            if(getToken(loginData)) {
                const name = loginData.name;
                const userName = loginData.userName;
                const userId = loginData.id;

                s.name = name || '';
                s.userName = userName || '';
                s.userId = parseInt(userId || '0', 10);
                s.clientId = parseInt(loginData.ClientId, 10);
                s.admin = loginData.Admin === 'true';
                s.globalAdmin = loginData.GlobalAdmin === 'true';
                s.viewDemographics = loginData.ViewDemographics === 'true';
                s.expires = loginData[".expires"];
                s.token = loginData.access_token;
                s.lenderAssisted = loginData.LenderAssisted === 'true';

                if(this.bugsnagClient) {
                    this.bugsnagClient.user = {
                        id: userId,
                        name: name,
                        email: userName,
                    };
                }

                this.onAuthChangeCallback && this.onAuthChangeCallback(true);
            }

            // Check for branch auth
            if(getBranchUserToken()) {
                const storage = getLocalStorage();
                s.branchUser = storage.getItem('branchUser') || '';
            }
        }, 'initialize');
    }

    async login(userName: string, password: string, remember: boolean): Promise<LoginErrorResponse | LoginData> {
        // Remove any current auth
        clearLoginData();

        // Contact the token server
        const result = await postForm<LoginData, TokenResponseError>('/api/token', {
            grant_type: 'password',
            username: userName,
            password: password,
        });

        if(result.isError === true) {
            clearLoginData();
            return {
                isError: true,
                errorMessage: result.payload.error_description,
                expiredUrl: result.payload.error_uri,
            };
        }

        const { payload } = result;

        // Store results in local/session storage
        setLoginData(payload, remember);


        // Mark this client as having logged in successfully
        localStorage.setItem('successful-login', 'true');
		gaLogin();

        this.update(s => {
            s.name = payload.name;
            s.userName = userName;
            s.userId = parseInt(payload.id, 10);
            s.clientId = parseInt(payload.ClientId, 10);
            s.admin = payload.Admin === 'true';
            s.globalAdmin = payload.GlobalAdmin === 'true';
            s.viewDemographics = payload.ViewDemographics === 'true';
            s.expires = payload[".expires"];
            s.token = payload.access_token;
            s.lenderAssisted = payload.LenderAssisted === 'true';
        }, 'login');

        // Set APM user details
        if(this.bugsnagClient) {
            this.bugsnagClient.user = {
                id: payload.id,
                name: payload.name,
                email: userName,
            };
        }

        this.onAuthChangeCallback && this.onAuthChangeCallback(true);

        return payload;
    }
    logoff() {
        this.update(s => {
            s.name = '';
            s.userName = '';
            s.userId = 0;
            s.clientId = 0;
            s.admin = false;
            s.globalAdmin = false;
            s.viewDemographics = false;
            s.expires = undefined;
            s.token = undefined;
            s.lenderAssisted = undefined;
        }, 'logoff');

        this.onAuthChangeCallback && this.onAuthChangeCallback(false);

        // Remove any current token
        clearLoginData();
    }

    async branchLogin(userName: string, password: string) {
        // Contact the token server
        const result = await postForm<LoginData, TokenResponseError>('/api/token?type=signup', {
            grant_type: 'password',
            username: userName,
            password: password,
        });

        if(result.isError === true) {
            clearBranchLoginData();
            return {
                isError: true,
                errorMessage: result.payload.error_description,
                expiredUrl: result.payload.error_uri,
            };
        }

        // Set login info
        setBranchLoginData(result.payload);

        // Update application state
        this.update(s => { s.branchUser = userName; }, 'branchLogin');

        return { error: '' };
    }
    branchLogoff() {
        this.update(s => {
            s.branchLoginModalOpen = false;
            s.branchUser = '';
        }, 'branchLogoff');

        clearBranchLoginData();
    }

    toggleBranchLoginModal(visible: boolean) {
        this.update(s => { s.branchLoginModalOpen = visible; }, 'toggleBranchLoginModal');
    }

    updateUser({ email, name }: UpdateUserParams) {
        const tokenStorage = getTokenStorage();
        this.update(s => {
            if(email !== undefined) {
                tokenStorage.setItem('userName', email);
                s.userName = email;
            }
            if(name !== undefined) {
                tokenStorage.setItem('name', name);
                s.name = name;
            }
        }, 'updateUser');
    }
}
export const auth = new AuthService({
    admin: false,
    globalAdmin: false,
    name: '',
    userId: 0,
    clientId: 0,
    userName: '',
    emailVerified: false,
    viewDemographics: false,

    branchUser: '',
    branchLoginModalOpen: false,
    ui: {
        actions: {},
    },
});
