import * as React from 'react';
import MasonryComponent, { MasonryOptions } from 'react-masonry-component';

interface Props {
    options?: MasonryOptions;
    style?: HTMLStyleElement;
    className?: string;
    elementType?: string;
}

export const Masonry: React.FC<Props> = props => {
    const { children, options, style, className, elementType } = props;

    return <MasonryComponent options={options} style={style} className={className} elementType={elementType}>
        {children}
    </MasonryComponent>;
};
