import * as React from 'react';
import styled from 'styled-components';
import { classNames } from 'web-shared/lib';
import { IconNames } from './icon';

const StyledSteps = styled.div`
--rs-icon-diameter: 30px;
--steps-icon-spacing: 10px;
--steps-icon-space-width: calc(var(--rs-icon-diameter) + var(--steps-icon-spacing));
--steps-icon-size: 16px;
--steps-icon-line-height: calc((var(--rs-icon-diameter) - 2px) / var(--steps-icon-size));

--small-steps-icon-diameter:             24px;
--small-steps-icon-space-width:          calc(var(--small-steps-icon-diameter) + var(--steps-icon-spacing));
--small-steps-icon-line-height:          calc(((var(--small-steps-icon-diameter) - 2px) / calc(--font-size-base)));
--small-steps-title-line-height:         calc((var(--small-steps-icon-diameter) / var(--font-size-base)));
--small-steps-icon-size:                 var(--font-size-base);
--small-steps-description-font-size:     var(--font-size-small);
--small-steps-description-line-height:   var(--line-height-small);
--small-steps-description-margin-top:    12px;

.rs-steps {
  min-height: var(--rs-icon-diameter);
}

// Item
// --------------------------
.rs-steps-item {
  padding-left: var(--steps-icon-space-width);
  position: relative;
  overflow: hidden;
}

// Item icon
.rs-steps-item-icon-wrapper {
  width: var(--rs-icon-diameter);
  height: var(--rs-icon-diameter);
  text-align: center;
  font-size: var(--steps-icon-size);
  line-height: var(--steps-icon-line-height);
  color: var(--rs-text-secondary);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  border: 1px solid var(--rs-steps-border);
  border-radius: 50%;

  // Finish status show check mark.
  .rs-steps-item-status-finish & {
    border-color: var(--rs-steps-state-finish);
    color: var(--rs-steps-state-finish);
  }

  // Error status show X mark.
  .rs-steps-item-status-error & {
    border-color: var(--rs-steps-state-error);
    color: var(--rs-steps-state-error);
  }

  .rs-steps-item-status-process & {
    border-color: var(--rs-steps-state-process);
    background-color: var(--rs-steps-state-process);
    color: var(--rs-text-inverse);

    .rs-icon {
      color: var(--rs-steps-icon-state-process);
    }
  }

  &.rs-steps-item-custom-icon {
    border: none;
    background: none;
  }

  // Icon content
  > .rs-steps-item-icon {
    width: 100%;
    display: block;
    text-align: center;
    position: relative;

    .rs-icon {
      font-size: 14px;
      vertical-align: middle;
    }
  }
}

// Item content
// --------------------------
.rs-steps-item-content {
  color: var(--rs-text-secondary);
  display: inline-block;
  position: relative;
  width: 100%;

  .rs-steps-item-status-process & {
    color: var(--rs-text-primary);
  }

  .rs-steps-item-status-error & {
    color: var(--rs-text-error);
  }
}

// Item title
.rs-steps-item-title {
  position: relative;
  font-size: var(--steps-title-font-size);
  line-height: var(--steps-title-line-height);
  min-height: var(--steps-title-font-size);
  padding-right: var(--steps-icon-spacing);
  display: inline-block;

  &:empty {
    padding-right: 0;
  }
}

// Item description
.rs-steps-item-description {
  margin-top: var(--steps-description-margin-top);
}

.rs-steps-item-tail,
.rs-steps-item-title::after {
  position: absolute;
  border-color: var(--rs-steps-border);

  .rs-steps-item-status-process & {
    border-color: var(--rs-steps-border);
  }

  .rs-steps-item-status-finish & {
    border-color: var(--rs-steps-state-finish);
  }

  .rs-steps-next-error & {
    border-color: var(--rs-steps-border-state-error);
  }
}

// Horizontal
.rs-steps-horizontal {
  display: flex;
  justify-content: space-between;

  .rs-steps-item {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: var(--rs-icon-diameter);

    // The last node is self-adaptive width.
    &:last-child {
      flex-grow: 0;
      flex-shrink: 0;

      // The last item hidden tail line
      .rs-steps-item-title::after {
        display: none;
      }
    }

    // There is a 10px spacing between each item.
    &:not(:first-child) {
      padding-left: calc(var(--steps-icon-space-width) + var(--steps-item-spacing));

      .rs-steps-item-icon-wrapper {
        left: var(--steps-item-spacing);
      }
    }
  }

  .rs-steps-item-content {
    height: calc(var(--steps-title-font-size) * var(--steps-title-line-height));
  }

  .rs-steps-item-title {
    &::after {
      content: '';
      top: calc(var(--rs-icon-diameter) / 2);
      left: 100%;
      width: 9999px;
      border-top-width: 1px;
      border-top-style: solid;
    }
  }
}

// Verticla
.rs-steps-vertical {
  .rs-steps-item {
    padding-bottom: 20px;

    // There is a 10px spacing between each item.
    &:not(:first-child) {
      margin-top: var(--steps-item-spacing);
    }

    &:last-child .rs-steps-item-tail {
      display: none;
    }
  }

  .rs-steps-item-tail {
    top: var(--steps-icon-space-width);
    bottom: 0;
    left: calc(var(--rs-icon-diameter) / 2);
    border-left-width: 1px;
    border-left-style: solid;
  }
}

// Small
// Resize: Diameter、font-size and so on.
.rs-steps-small {
  min-height: var(--small-steps-icon-diameter);

  &.rs-steps-horizontal .rs-steps-item {
    min-height: var(--small-steps-icon-diameter);
  }

  &.rs-steps-horizontal .rs-steps-item:not(:first-child) {
    padding-left: calc(var(--small-steps-icon-space-width) + var(--steps-icon-spacing));
  }

  &.rs-steps-vertical .rs-steps-item-tail {
	left: calc(var(--small-steps-icon-diameter) / 2);
  }

  &.rs-steps-horizontal .rs-steps-item-title::after {
    top: 12px;
  }

  .rs-steps-item {
    padding-left: var(--small-steps-icon-space-width);
  }

  .rs-steps-item-icon-wrapper {
    width: var(--small-steps-icon-diameter);
    height: var(--small-steps-icon-diameter);
    font-size: var(--small-steps-icon-size);
    line-height: var(--small-steps-icon-line-height);
  }

  .rs-steps-item-title {
    font-size: var(--small-steps-icon-size);
    line-height: var(--small-steps-title-line-height);
  }

  .rs-steps-item-description {
    margin-top: var(--small-steps-description-margin-top);
    font-size: var(--small-steps-description-font-size);
    line-height: var(--small-steps-description-line-height);
  }
}
`;

interface Props {
	vertical?: boolean;
	small?: boolean;
	current?: number;
	currentStatus?: 'finish' | 'wait' | 'process' | 'error';
}
export const Steps: React.FC<Props> = ({ children, vertical, small, current, currentStatus }) => {
	const horizontal = !vertical;
	const count = React.Children.count(children);
	const items = mapCloneElement(children, (item, index) => {
		const itemStyles = {
			flexBasis: index < count - 1 ? `${100 / (count - 1)}%` : undefined,
			maxWidth: index === count - 1 ? `${100 / count}%` : undefined
		};
		const itemProps = {
			stepNumber: index + 1,
			status: 'wait',
			style: horizontal ? itemStyles : undefined,
			...item.props
		};

		// fix tail color
		if(currentStatus === 'error' && index === current! - 1) {
			itemProps.className = 'rs-steps-next-error';
		}

		if(!item.props.status) {
			if(index === current) {
				itemProps.status = currentStatus;
				itemProps.className = 'rs-steps-item-active';
			} else if(index < current!) {
				itemProps.status = 'finish';
			}
		}

		return itemProps;
	});

	return (
		<StyledSteps className={classNames({ 'rs-steps-small': small, 'rs-steps-vertical': vertical, 'rs-steps-horizontal': horizontal })}>
			{items}
		</StyledSteps>
	);
};

const StyledStep = styled.div`
`;

interface StepProps {
	itemWidth?: number | string;
	status?: 'finish' | 'wait' | 'process' | 'error';
	icon?: IconNames;
	stepNumber?: number;
	description?: React.ReactNode;
	title?: React.ReactNode;
}
export const Step: React.FC<StepProps> = ({ itemWidth, status, icon, stepNumber, description, title }) => {
	let iconNode = <span className={classNames('steps-item-icon', `steps-item-icon-${status}`)}>
		{status ? STEP_STATUS_ICON[status] ?? stepNumber : stepNumber}
	</span>;

	if(icon)
		iconNode = <span className="rs-steps-item-icon">{icon}</span>;

	const styles: React.CSSProperties = { width: itemWidth };

	return <StyledStep style={styles}>
		<div className="rs-steps-item-tail" />
		<div className={classNames('steps-item-icon-wrapper', icon ? 'steps-item-custom-icon' : '')}>{iconNode}</div>
		<div className="rs-steps-item-content">
			{<div className="rs-steps-item-title">{title}</div>}
			{description && <div className="rs-steps-item-description">{description}</div>}
		</div>
	</StyledStep>;
};

const STEP_STATUS_ICON: {
	[key in NonNullable<StepProps['status']>]: React.ReactElement | null;
} = {
	finish: <span className="fa fa-check" />,
	wait: null,
	process: null,
	error: <span className="fa fa-times" />
};

type ReactChildrenMapFunc = (child: any, index: number) => any;
function map(children: React.ReactNode, func: ReactChildrenMapFunc, context?: any) {
	let index = 0;
	return React.Children.map(children, child => {
		if(!React.isValidElement(child)) {
			return child;
		}
		const handle = func.call(context, child, index);
		index += 1;
		return handle;
	});
}
function mapCloneElement(children: React.ReactNode, func: ReactChildrenMapFunc, context?: any) {
	return map(
		children,
		(child: React.DetailedReactHTMLElement<any, HTMLElement>, index: number) =>
			React.cloneElement(child, {
				key: index,
				...func(child, index)
			}),
		context
	);
}
