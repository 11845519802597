import * as React from 'react';

import { config } from 'web-frontend/lib';

interface Props {
    size: 'h2' | 'h3' | 'h4';
}
export const Logo: React.FC<Props> = function ({ size = 'h4' }) {
    const styles = {
        h2: { height: 40 },
        h3: { height: 22 },
        h4: { height: 18 },
    };

    return <img src={config.site.logo}
        style={styles[size]}
        className="logo-inline"
        title={config.site.name}
        alt={config.site.name}
    />;
};
