import * as React from 'react';

interface Props {
    feature: api.LandingPageContent;
}
export const Product: React.FC<Props> = function ({ feature, children }) {
    const details = (feature.details || '').split(/(\r\n|\n)/g).filter(d => d.trim() !== '');

    return <div className="columns">
        <style jsx>{`
            .columns { margin-top: 1em; }
            .product-heading { height: 85px; color: var(--color-secondary); display: flex; align-items: center; }
            .icon { font-size: 4em; height: 1em; width: 1em; margin: 0; }
            .stackedIcon { font-size: inherit; position: absolute; left: 10px; background-color: #FFF; top: 10px; margin: 0; }
            .panel-body { font-size: 1.2em; }
            .details { margin-bottom: 15px; }
            .subTitle {
                font-size: 1.4em; margin-bottom: 15px; text-align: center;
                color: #244062;
                color: var(--color-secondary);
            }
            .detailItem { font-size: 1.3em; margin: 5px 0; margin-left: 40px; }
            .detailIcon {
                float: left; font-size: 2em;
                color: #244062;
                color: var(--color-primary);
            }
            .banner {
                font-size: 2em; color: #fff; padding: 20px; margin-top: 20px;
                background-color: #244062;
                background-color: var(--color-secondary);
            }
            .product-name { font-size: 1.8em; line-height: 1.1; margin-left: 1em; }
        `}</style>

        <div className="column is-5">
            <div className="product-heading">
                <span className={`icon fa fa-${feature.summaryIcon}`}></span>
                <div className="product-name">{feature.name}</div>
            </div>
            <div className="panel-body">
                {children}
            </div>
        </div>

        <div className="column is-7 col-lg-6 details">
            <div className="subTitle">{feature.subTitle}</div>
            {details.map((d, i) =>
                <div key={i}>
                    <span className="fa fa-check-square detailIcon" />
                    <div key={i} className="detailItem" dangerouslySetInnerHTML={{ __html: d }}></div>
                </div>,
            )}
        </div>

        {feature.bannerText ? <div className="has-text-centered is-12 banner">
            {feature.bannerText}
        </div> : null}
    </div>;
};
