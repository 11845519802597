import * as React from 'react';
import { RouteChildrenProps } from 'react-router-dom';

import { useHeaderLinks } from 'web-shared/lib';
import { HeaderPane } from 'web-frontend/components';
import { ProductsPane, FaqPane, GetStartedPane } from './components';

import { config as pageConfig} from 'web-frontend/lib';

interface Props extends RouteChildrenProps {
    url: string;
    config: api.LandingPageConfig;
}
export const LandingPage: React.FC<Props> = ({ url, config }) => {
    useHeaderLinks([]);

    const { getStarted } = config;
    const faqs = config.faqConfig?.faqs?.filter(f => f.favorite);

    return <div style={{ height: '100%' }}>
        <HeaderPane banners={config.banners} />
        <ProductsPane features={config.content} featureSummaryText={''} />
        {faqs.length ? <FaqPane landing={url} faqs={faqs} /> : null}
        {pageConfig.site.type !== 'paycheck' ? <GetStartedPane heading={getStarted?.heading} text={getStarted?.body} url={getStarted?.linkUrl} /> : null}
    </div>;
};
