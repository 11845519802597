import * as React from 'react';

import { classNames } from '../lib';

interface Props {
    id?: string;
    color?: 'none' | 'primary' | 'secondary' | 'inverse' | 'white' | 'primary-light' | 'secondary-light';
    style?: React.CSSProperties;
    fluid?: boolean;
    css?: string;
    sticky?: boolean | number;
    className?: string;
}
export const Section: React.FC<Props> = function (props) {
    const { color = 'none', style = {}, fluid = false, css, sticky, className } = props;

    const _colors = {
        none: '',
        white: 'background-color: #fff;',
        primary: `color: #fff; background-color: #4f6228; background-color: var(--color-primary);`,
        'primary-light': `color: #fff; background-color: #728153; background-color: var(--color-primary-light-25);`,
        secondary: `color: #fff; background-color: #244062; background-color: var(--color-secondary);`,
        'secondary-light': `color: #fff; background-color: #506681; background-color: var(--color-secondary-light-25);`,
        inverse: `background-color: #222; color: #fff;`,
    };

    const isSticky = sticky !== undefined && sticky !== false;
    const stickyPos = sticky === true || !isSticky ? 0 : sticky as number;

    return <section id={props.id} className={classNames('section-div', className, { sticky: isSticky })} style={style}>
        <style jsx>{`
            .section-div {
                ${_colors[color]};
                ${css === undefined ? '' : css}
                padding: 1em 0.5em;
            }
            .sticky { position: sticky; top: ${stickyPos}px; z-index: 99; }
            .container { position: relative; }
        `}</style>

        <div className={classNames({ container: true, 'is-fluid': fluid })}>
            {props.children}
        </div>
    </section>;
};
