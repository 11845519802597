import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import { Switch, useLocation } from 'react-router-dom';
import { Route, RouteProps } from 'react-router-dom';

import { Modal, PublicRoute, PrivateRoute, NonIdealState } from 'web-shared/components';
import { Header, Footer } from 'web-frontend/components';
import { gaPageView, hexToCssVars } from 'web-shared/lib';

import { config } from 'web-frontend/lib';
import { auth, ui } from 'web-shared/store';
import { initializeBugsnag } from 'web-shared/lib/initialize';

import { LoginForm } from 'web-shared/pages/login';
import LoginRegisterPage from './pages/account/loginOrRegister';
import LogoffPage from 'web-shared/pages/logoff';
import NotFoundPage from 'web-shared/pages/notFound';
import { LandingPage } from './pages/landing/landing';

const ForgotPage = React.lazy(() => import('web-shared/pages/forgot'));
const ResetPage = React.lazy(() => import('web-shared/pages/reset'));
const VerifyPage = React.lazy(() => import('web-shared/pages/verify'));
const AccountSetupPage = React.lazy(() => import('./pages/account/account-setup'));

const MainLandingPage = React.lazy(() => import('./pages/landing'));
const QuestionsPage = React.lazy(() => import('./pages/questions/questions'));
const AboutPage = React.lazy(() => import('./pages/about'));
const UserPage = React.lazy(() => import('./pages/user'));
const ApplicationDetailPage = React.lazy(() => import('./pages/user/application[id]'));
const RenewalDetailPage = React.lazy(() => import('./pages/user/renewal[id]'));
const ActionPage = React.lazy(() => import('./pages/user/action[id]'));
const SignupPage = React.lazy(() => import(`./pages/signup`));
const ApplicationRenderer = React.lazy(() => import('./pages/secured/apply/index'));

const AdminPage = React.lazy(() => import('./pages/secured/admin'));
const AdminAppsPage = React.lazy(() => import('./pages/secured/admin/apps/applications'));
const AdminAppDetailPage = React.lazy(() => import('./pages/secured/admin/apps/detail'));

const PPPAdminPage = React.lazy(() => import(`./pages/ppp/loan/admin`));
const PPPAdminDetailPage = React.lazy(() => import(`./pages/ppp/loan/admin/ppl-detail`));

const PppApplicationPage = React.lazy(() => import('./pages/ppp/loan/application/apply'));
const ForgivenessApplicationPage = React.lazy(() => import('./pages/ppp/forgiveness/application/apply'));
const ForgivenessAdminPage = React.lazy(() => import(`./pages/ppp/forgiveness/admin/ppf-applications`));
const ForgivenessAdminDetailPage = React.lazy(() => import(`./pages/ppp/forgiveness/admin/ppf-detail`));

const AdminRoute: React.FC<RouteProps & { title: string, token: string | undefined }> = ({ children, title, token, component, ...rest }) => {
    return <Route {...rest} render={
        (props) => {
            const authState = auth.getStateSnapshot();
            const isAdmin = authState.admin || authState.globalAdmin;
            if(!isAdmin) {
                return <PrivateRoute {...props} token={token} component={NotFoundPage} title="Page Not Found" />;
            }
            return <PrivateRoute {...{ children, title, token, component}} {...props} />;
        }
    } />;
};

import { initializeGoogleAnalytics } from './lib/googleAnalytics';
initializeGoogleAnalytics();

const bugsnagClient = initializeBugsnag();
auth.initialize(bugsnagClient);
ui.initialize(config.client.name);

const App: React.FC = function() {
    const { expires, token } = auth.useState(s => ({
        expires: s.expires,
        token: s.token,
    }));

    const location = useLocation();
    React.useEffect(() => {
        gaPageView(location);
    }, [location]);

    // Set a timer to refresh the page when login expires
    const [, setState] = React.useState(new Date().getTime());
    React.useEffect(() => {
        if(!expires) {
            return;
        }
        const expireMs = new Date(expires).getTime() - new Date().getTime() + 500;
        const timeout = setTimeout(() => {
            setState(new Date().getTime());
        }, expireMs);

        return () => { clearTimeout(timeout); };
    }, [expires]);

    const isPpp = config.site.type === 'paycheck';
    const hasMl = true;

    const { securedSites = [] } = config.site;
    const hasSecured = securedSites.length > 0;
    const hasExpressLender = securedSites.includes('express-lender');

    return <>
        {/* <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
            integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
            crossOrigin="anonymous" /> */}

        <style jsx global>{`
            input.modified:invalid, select.modified:invalid { border-color: #E6B8B7; }
            input.modified:valid, select.modified:valid { border-color: #C4D79B; }
            :root {
                margin: 0;
                ${hexToCssVars('primary', config.colors.primary)}
                ${hexToCssVars('secondary', config.colors.secondary)}
                ${hexToCssVars('highlight', config.colors.highlight)}
            }
        `}</style>

        <style jsx>{`
            .main { flex-grow: 1; }
            #content {
                display: flex;
                flex-direction: column;
                min-height: 100vh;
            }
        `}</style>

        <div id="content">
            {expires && new Date(expires) < new Date()
                ? <Modal title="Please login to continue." okText="" fullHeight={true}>
                    <LoginForm skipRedirect title="Your session has expired." />
                </Modal> : null}
            <Header option={1} />
            <main className="main">
                <React.Suspense fallback={<NonIdealState spinner='circle' />}>
                    <Switch>
                        <PublicRoute exact path="/" component={MainLandingPage} title={config.client.name} />
                        <PublicRoute path="/logoff" component={LogoffPage} title="Logoff" />
                        <PublicRoute path="/login" component={LoginRegisterPage} title="Login or Register" />
                        <PublicRoute path="/create" component={LoginRegisterPage} title="Login or Register" />
                        <PublicRoute path="/forgot" component={ForgotPage} title="Forgot Password" />
                        <PublicRoute path="/reset" component={ResetPage} title="Reset Password" />
                        <PublicRoute path="/verify" component={VerifyPage} title="Verify Email" />
                        <PublicRoute path="/account-setup" component={AccountSetupPage} title="Account Setup" />

                        <PrivateRoute token={token} path="/user/action/:id" component={ActionPage} title="Action Details" />
                        <PrivateRoute token={token} path="/user/renewal/:id" component={RenewalDetailPage} title="Renewal Details" />
                        <PrivateRoute token={token} path="/user/applications/:id" component={ApplicationDetailPage} title="Application Details" />
                        <PrivateRoute token={token} path="/user" component={UserPage} title="User Profile" />

                        {/* Hard-coded SBA route for express lender and CCM */}
						{hasExpressLender ? <PrivateRoute token={token} path="/apply/sba7a" component={ApplicationRenderer} applicationType="express-lender" title="Application" /> : null}
						{hasExpressLender ? <PrivateRoute token={token} path="/apply/ccm" component={ApplicationRenderer} applicationType="express-lender" title="Application" /> : null}

                        {/* Map all sites to a sub-path */}
                        {securedSites.map(s => <PrivateRoute key={s} token={token} path={`/apply/${s}`} component={ApplicationRenderer} applicationType={s} title="Application" />)}

                        {/* Any secured app detail page */}
                        {hasSecured ? <PrivateRoute token={token} path="/apply/:id" component={ApplicationRenderer} title="Application" /> : null}

                        {/* Allow default routes */}
                        {securedSites.length === 1 ? <PrivateRoute token={token} path="/apply" component={ApplicationRenderer} applicationType={securedSites[0]} title="Application" /> : null}

                        {/* Render secured admin routes */}
                        {hasSecured ? <AdminRoute token={token} path="/admin/apps/:id" component={AdminAppDetailPage} title="ALEX Application" /> : null}
                        {hasSecured ? <AdminRoute token={token} path="/admin/apps" component={AdminAppsPage} title="ALEX Applications" /> : null}
                        {hasSecured ? <AdminRoute token={token} path="/admin" component={AdminPage} title="ALEX Administration" /> : null}

                        {hasMl ? <PrivateRoute token={token} path="/signup/e-sign/:id" component={SignupPage} title="E-Sign Loan Documents" /> : null}
                        {hasMl ? <PrivateRoute token={token} path="/signup/:id" component={isPpp ? PppApplicationPage : SignupPage} title="Update Application" /> : null}
                        {hasMl ? <PrivateRoute token={token} path="/signup" component={isPpp ? PppApplicationPage : SignupPage} title="Application" /> : null}

                        {isPpp ? <PrivateRoute token={token} path="/forgiveness/apply" component={ForgivenessApplicationPage} title="PPP Forgiveness Application" /> : null}
                        {isPpp ? <AdminRoute token={token} path="/ppl/application/:id" component={PPPAdminDetailPage} title="Payroll Protection Application Detail" /> : null}
                        {isPpp ? <AdminRoute token={token} path="/ppl/:id" component={PPPAdminDetailPage} title="Payroll Protection Application Detail" /> : null}
                        {isPpp ? <AdminRoute token={token} path="/ppl" component={PPPAdminPage} title="Payroll Protection Applications" /> : null}
                        {isPpp ? <AdminRoute token={token} path="/ppf/:id" component={ForgivenessAdminDetailPage} title="Payroll Protection Forgiveness Detail" /> : null}
                        {isPpp ? <AdminRoute token={token} path="/ppf" component={ForgivenessAdminPage} title="Payroll Protection Forgiveness" /> : null}

                        <PublicRoute path="/about" component={AboutPage} title="About" />
                        <PublicRoute path="/questions" component={QuestionsPage} title="Questions" />

                        {config.landingPages.map(l => <PublicRoute key={l.url} path={`${l.url}/questions`} title={l.config.title} component={QuestionsPage}
                            componentProps={{ config: l.config.faqConfig }} />)}
                        {config.landingPages.map(l => <PublicRoute key={l.url} path={l.url} title={l.config.title} component={LandingPage}
                            componentProps={{ url: l.url, config: l.config }} />)}

                        <PublicRoute component={NotFoundPage} title="Page Not Found" />
                    </Switch>
                </React.Suspense>
            </main>
            <Footer />
        </div>
    </>;
};

export const HotApp = hot(App);
