import * as React from 'react';
import styled from 'styled-components';
import Axios, { CancelTokenSource } from 'axios';

import { Button } from 'web-shared/components/button';
import { Modal, NonIdealState } from 'web-shared/components';
import { classNames, formatNumber, postForm } from 'web-shared/lib';
import { Spinner } from './spinners/Spinner';

const StyledDiv = styled.div`
	.header-text { font-size: 1.6em; }
	.instructions { font-size: 1.1em; font-style: italic; }
	.uploader { display: flex; flex-direction: row; margin-top: 1em; }
	.dropzone {
		padding: 2em;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		box-sizing: border-box;
	}
	.dropzone > div {
		height: 200px;
		width: 200px;
		border: 2px dashed rgb(187, 186, 186);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-size: 16px;
		cursor: pointer;
	}
	.file-input { display: none; }
	.file-list { flex-grow: 1; }
	.actions {
		display: flex;
		justify-content: flex-end;
		margin-top: 2em;
	}
	.actions .spacer { flex: 1; }
	.highlight { background-color: var(--color-primary-light-90); }
	.table { background: transparent; }
	.table td { font-size: 0.8em; background: transparent; }
	.table td:first-child { text-align: center; width: 40px; }
	&& .ar { text-align: right; }
	.uploader .fa-times { color: #c0504d; cursor: pointer; }
	.nodrop .dropzone > div { cursor: no-drop; }
	.error { color: #c0504d; }
	.fa-check { color: #3b4a1e; }
	&& .progress-row {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 0;
		background-color: #4F622838;
		padding: 0;
		pointer-events: none;
	}
`;

interface FileStatus {
	file: File;
	pct: number;
	status?: 'uploading' | 'success' | 'failed' | 'aborted';
	errorMsg?: string;
}

interface Props<T> {
	title: string;
	disabled?: boolean;
	uploadUrl: string;
	uploadFields?: { [name: string]: string | number };
	onClose?: () => void;
	onComplete?: (file: File, response: T) => void;
	accept?: string[];
}
export const UploadModal = function UploadModal<T>(props: Props<T>) {
	const { title, disabled = false, onClose, onComplete, uploadUrl, uploadFields = {}, accept } = props;
	const [uploading, setUploading] = React.useState(false);
	const [highlight, setHighlight] = React.useState(false);
	const [files, setFiles] = React.useState<FileStatus[]>([]);
	const fileInputRef = React.useRef<HTMLInputElement>(undefined);
	const cancelTokenRef = React.useRef<CancelTokenSource>(undefined);

	function openFileDialog() {
		if(disabled || uploading)
			return;
		fileInputRef.current.click();
	}
	function onFilesAdded(e: React.ChangeEvent<HTMLInputElement>) {
		if(disabled || uploading || !e.target.files) {
			return;
		}
		addFiles(e.target.files)
	}
	function onDragOver(e: React.DragEvent<HTMLDivElement>) {
		e.preventDefault();
		if(disabled || uploading) {
			e.dataTransfer.dropEffect = 'none';
			return;
		}
		setHighlight(true);
	}
	function onDrop(e: React.DragEvent<HTMLDivElement>) {
		e.preventDefault();
		if(disabled || uploading) {
			e.dataTransfer.dropEffect = 'none';
			return;
		}

		addFiles(e.dataTransfer.files);
		setHighlight(false);
	}
	function addFiles(fileList: FileList) {
		const newFiles = [...fileList].map(f => ({ file: f, pct: 0 }));
		setFiles(f => [...f, ...newFiles]);
	}

	async function beginUpload() {
		setUploading(true);

		for(let i = 0; i < files.length; i++) {
			const fs = files[i];
			if(fs.status === 'success')
				continue;

			fs.errorMsg = undefined;
			fs.status = undefined;
			fs.pct = 0;
		}
		setFiles([...files]);

		for(let i = 0; i < files.length; i++) {
			const fs = files[i];
			if(fs.status === 'success')
				continue;

			const file = fs.file;

			// Add the file to the form data
			const formData = new FormData();
			formData.append("file", file, file.name);

			// Add additional fields to the form data
			if(uploadFields) {
				Object.keys(uploadFields).map(k => {
					formData.append(k, String(uploadFields[k]));
				});
			}

			fs.status = 'uploading';
			setFiles([...files]);

			// Upload the file
			cancelTokenRef.current = Axios.CancelToken.source();
			const response = await postForm<T>(uploadUrl, formData,
				cancelTokenRef.current.token, e => {
					const pct = Math.round(e.loaded * 1.0 / e.total * 10000) / 100;
					fs.pct = pct;
					setFiles([...files]);
				}
			);

			if(cancelTokenRef.current.token.reason?.message) {
				fs.status = 'aborted';
				fs.errorMsg = 'Aborted';
				fs.pct = 0;
				setFiles([...files]);
				break;
			} else {
				if(response.isError) {
					fs.status = 'failed';
					fs.errorMsg = response.error;
					fs.pct = 0;
				} else {
					fs.status = 'success';

					// Tell the component we successfully uploaded a file
					onComplete && onComplete(file, response.payload as T);
				}
				setFiles([...files]);
			}
		}

		setUploading(false);
	}


	const hasBegun = files.some(f => f.status != undefined);
	const isComplete = hasBegun && files.every(f => f.status === 'success');
	return <Modal title="" maxWidth="900px" onClose={() => { }} okText="" fullHeight={false}>
		<StyledDiv>
			<div className="header-text">{title}</div>
			<div className="instructions">
				Click on the upload button below or drag your files below to get started. Once you have selected all the files you wish to upload, click the button below to send.
			</div>
			<div className={classNames('uploader', { highlight, nodrop: uploading || disabled })}
				onDragOver={onDragOver}
				onDragLeave={() => setHighlight(false)}
				onDrop={onDrop}
				>
				<div className="dropzone"
					onClick={() => openFileDialog()}
				>
					<div>
						<span className="fa fa-upload fa-2x" />
						<input ref={fileInputRef} accept={accept?.join(',')} className="file-input" type="file" multiple onChange={onFilesAdded} />
						<div>Click here to</div><div>select file(s).</div>
					</div>
				</div>
				<div className="file-list">
					{files.length
						? <table className="table is-condensed is-fullwidth is-hoverable">
							<thead>
								<tr>
									<th></th>
									<th>Name</th>
									<th className="ar">Size</th>
									{hasBegun ? <th>Status</th> : null}
								</tr>
							</thead>
							<tbody>
								{files.map((f, i) => <tr key={i} style={{ position: 'relative' }}>
									<td>
										{f.status === 'success' ? <span className="fa fa-check" />
											: !uploading ? <span className="fa fa-times" title="Remove this file from the queue." onClick={() => setFiles(files.filter((_f, index) => i !== index))} />
												: f.status === 'uploading' ? <Spinner type="circle" heightEm={0.7} />
													: f.status === 'failed' ? <span className="fa fa-warning" />
														: f.status === 'aborted' ? <span className="fa fa-warning" />
															: null}
									</td>
									<td>{f.file.name}</td>
									<td className="ar">{formatNumber(f.file.size)}</td>
									{hasBegun ? <td>
										{f.errorMsg ? <div className="error">{f.errorMsg}</div> : <div></div>}
									</td> : null}
									<td className="progress-row" style={{ width: `${f.pct}%` }}></td>
								</tr>)}
							</tbody>
						</table>
						: <NonIdealState title="No files selected." subTitle="Drop files here to begin." icon="files-o" />}
				</div>
			</div>

			<div className="actions">
				<div>
					{uploading || isComplete ? null : <Button icon="times" color="dark" onClick={() => { onClose && onClose(); }}>Close</Button>}
				</div>
				<div className="spacer"></div>
				<div>
					{isComplete ? <Button color="dark" disabled={files.length === 0} onClick={() => { onClose && onClose(); }}>Close</Button>
						: uploading ? <Button color="danger" onClick={() => { cancelTokenRef.current?.cancel('User requested abort.'); }}>Abort</Button>
						: <Button color="secondary" disabled={files.length === 0} onClick={() => { beginUpload(); }}>Upload Selected Files</Button>}
				</div>
			</div>
		</StyledDiv>
	</Modal>;
};
