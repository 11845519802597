import * as React from 'react';
import numeral from 'numeral';

interface Props {
    page: number;
    pageSize: number;
    totalResults: number;
    style?: {};
    hideResults?: boolean;
    onPageChange: (page: number) => any;
}

export const Pager: React.FC<Props> = (props) => {
    function _onNextPage() {
        const { onPageChange, page } = props;
        onPageChange && onPageChange(page + 1);
    }
    function _onPreviousPage () {
        const { onPageChange, page } = props;
        onPageChange && onPageChange(page - 1);
    }

    const { page, totalResults, pageSize, hideResults = false, style } = props;
    const maxPages = isNaN(Math.ceil(totalResults / pageSize)) ? '??' : Math.ceil(totalResults / pageSize);

    return <div>
        <style jsx>{`
            .pager { padding-Left: 0; margin: 20px 0; list-Style: none; text-Align: center; }
            .li { display: inline; padding-left: 5; }
        `}</style>

        <ul className="pager" style={style}>
            <span>Page {page} of {maxPages}</span>
            &nbsp;
            <li className="li">
                <button className="button is-small is-dark" onClick={_onPreviousPage} disabled={page === 1}>Previous</button>
            </li>
            &nbsp;
            <li className="li">
                <button className="button is-small is-dark" onClick={_onNextPage} disabled={page === maxPages}>Next</button>
            </li>
            &nbsp;
            { hideResults ? null : <span>{numeral(totalResults).format('0,0')} Results</span> }
        </ul>
    </div>;
};
