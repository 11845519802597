import axios, { CancelToken, AxiosResponse } from 'axios';
import { serializeFormData, getToken } from '../lib';

export type Failable<TSuccess, TError> = {
    isError: true;
    error: string;
    payload: TError;
} | {
    isError: false;
    payload: TSuccess;
};
export type UploadProgressFn = (e: ProgressEvent) => void;

export function postForm<TSuccess, TError = {}>(url: string, body: {}, cancelToken?: CancelToken, onUploadProgress?: UploadProgressFn) {
    return ajax<TSuccess, TError>(url, false, body, { method: 'POST', cancelToken, onUploadProgress });
}
export function postJson<TSuccess, TError = {}>(url: string, body?: {}, cancelToken?: CancelToken, onUploadProgress?: UploadProgressFn) {
    return ajax<TSuccess, TError>(url, true, body, { method: 'POST', cancelToken, onUploadProgress });
}
export function get<TSuccess, TError = {}>(url: string, cancelToken?: CancelToken, init: IAxiosOptions = {}) {
    return ajax<TSuccess, TError>(url, false, null, { method: 'GET', cancelToken, ...init });
}

interface IAxiosOptions {
    headers?: HeadersInit;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    cancelToken?: CancelToken;
    onUploadProgress?: UploadProgressFn;
    skipAuthorization?: boolean;
}
export async function ajax<TSuccess extends {}, TError extends {} = {}>(url: string, json: boolean, body?: {}, init: IAxiosOptions = {}): Promise<Failable<TSuccess, TError>> {
    const token = getToken();
    const method = init.method || 'GET';

    const isFormData = body && 'append' in body;
    const headers: HeadersInit = {
        ...init.headers,
        'Content-Type': isFormData ? 'multipart/form-data' : json ? 'application/json; charset=UTF-8' : 'application/x-www-form-urlencoded; charset=UTF-8',
        Authorization: init.skipAuthorization ? '' : token ? `Bearer ${token}` : '',
    };

    // Allow client ID to be specified for admin requests
    try {
        const currentClientId = 'localStorage' in window ? Number(localStorage.currentClientId) : undefined;
        if(!isNaN(currentClientId)) {
            headers['clientId'] = currentClientId.toString();
        }
    } catch { }

    let response: AxiosResponse<any>;
    try {
        response = await axios({
            url,
            method,
            data: method === 'GET' || !body ? null :
                json ? body : isFormData ? body : serializeFormData(body),
            cancelToken: init.cancelToken,
            headers,
            onUploadProgress: init.onUploadProgress,
        });
    } catch(ex) {
        // console.dir(ex);
        return {
            isError: true,
            error: ex?.response?.statusText || 'Unable to contact the server.',
            payload: {} as TError,
        };
    }

    // Try to decode the response as a typed object
    let payload: TSuccess | undefined;
    try {
        payload = response.data;
    } catch { }

    const payloadError = !payload ? undefined :
        (payload as any).error || (payload as any).errorMessage || (payload as any).error_message;

    const isError = response.status !== 200
        || !!payloadError
        || (payload && (payload as any).isError)
        || (payload && (payload as any).IsError);

    return isError
        ? { isError, error: payloadError || response.statusText || (response as any).message, payload: (payload || {}) as TError }
        : { isError, payload: (payload || {}) as TSuccess };
}
