import * as React from 'react';

import { Modal, NonIdealState } from 'web-shared/components';
import { postJson } from 'web-shared/lib';
import Axios, { CancelTokenSource } from 'axios';
import { gaEvent } from 'web-shared/lib';
import { Pdf } from './pdf';

interface DisclosureResponse {
    pdfContent: string;
    documentMd5: string;
}
interface Props {
    name: string;
    id: string | number;
    renewal?: boolean;
    ppl?: boolean;
    ppf?: boolean;
    parameters?: object;
    onOk: (id: string | number) => void;
    onClose?: () => void;
}
export const DisclosureModal: React.FC<Props> = ({ name, id, onOk, onClose, renewal, ppl, ppf, parameters = {} }) => {
    const [{ ready, failed, error }, setState] = React.useState({
        ready: false,
        failed: false,
        error: '',
    });
    const [results, setResults] = React.useState<DisclosureResponse>();

    async function getDisclosureData(tokenSource: CancelTokenSource) {
        setState({ ready: false, failed: false, error: '' });
        setResults(undefined);

        // URL is different for renewals
        const type = typeof (id) === 'string' ? 'template' : 'version';
        const url = renewal ? `/api/user/renewal/disclosure/${id}/base64`
            : ppl ? `/api/ppl/disclosure/${type}/${id}`
            : ppf ? `/api/forgiveness/disclosure/version/${id}`
            : `/api/signup/disclosure/${type}/${id}`;

        const response = await postJson<DisclosureResponse, undefined>(url, parameters, tokenSource.token);
        if(response.isError) {
            setState({ ready: false, failed: false, error: response.error });
        }

        setResults(response.payload);
    }

    React.useEffect(() => {
        const cancelSource = Axios.CancelToken.source();
        getDisclosureData(cancelSource);

        return () => {
            cancelSource && cancelSource.cancel();
        };
    }, [name, id]);

    function _documentLoaded() {
        gaEvent('signup', 'ViewDisclosure');
        setState({ ready: true, failed: false, error: '' });
    }
    function _documentFailed() {
        setState({ ready: false, failed: true, error: '' });
    }

    const loadingMessage = <NonIdealState title="Loading PDF..." color="#fff" fullPage spinner="circle" />;
    const isError = error || failed;

    return <Modal title={name}
        okText="Accept"
        cancelText="Close"
        onOk={() => onOk && onOk(id)}
        onClose={() => onClose && onClose()}
        isReady={ready}
        bodyStyle={{ backgroundColor: '#555' }}
    >
        <div style={{ margin: 'auto', textAlign: 'center' }}>
            {isError ? <NonIdealState fullPage title="PDF failed to load" color="#fff" icon="exclamation-triangle"
                subTitle={error} colorSubTitle="#efefef" /> : null}
            {!isError && !results?.pdfContent ? loadingMessage : null}
            {!isError && results?.pdfContent ?
                <Pdf content={results.pdfContent} downloadLink={`/api/signup/document/${results.documentMd5}`} scale={3}
                    onDocumentComplete={_documentLoaded} onDocumentFailed={_documentFailed} loading={loadingMessage} /> : null}
        </div>
    </Modal>;
};
