import * as React from 'react';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';
import PdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker';

if(typeof window !== 'undefined' && 'Worker' in window) {
    pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker();
}

export const PdfPage: React.FC<{ scale: number; page: pdfjs.PDFPageProxy }> = function ({ page, scale }) {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    React.useEffect(() => {
        const renderPdf = async () => {
            const canvas = canvasRef.current;
            const context = canvas?.getContext('2d');
            if(!canvas || !context)
                return;

            const viewport = page.getViewport({ scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            context.clearRect(0, 0, canvas.width, canvas.height);

            // Render PDF page into canvas context
            const renderTask = page.render({
                canvasContext: context!,
                viewport: viewport,
            });

            await renderTask.promise;
        };

        renderPdf();
    }, [page, scale]);

    return <canvas
        style={{ width: '100%', maxWidth: 1000 }}
        ref={canvasRef}
    />;
};

function getPdfFromByteArray(content: string = '') {
    const bytes = window.atob(content);
    const byteLength = bytes.length;
    const byteArray = new Uint8Array(byteLength);
    for(let index = 0; index < byteLength; index++) {
        byteArray[index] = bytes.charCodeAt(index);
    }
    return byteArray;
}

interface Props {
    file?: string;
    content?: string;
    scale: number;
    loading: React.ReactElement;
    downloadLink: string;
    onDocumentComplete: () => void;
    onDocumentFailed: () => void;
}
export const Pdf: React.FC<Props> = ({ file, content, scale = 1.0, loading: loadingScreen, downloadLink, onDocumentComplete, onDocumentFailed }) => {
    const [state, setState] = React.useState({
        pages: [] as pdfjs.PDFPageProxy[],
        ready: false,
    });

    React.useEffect(() => {
        const fetchPdf = async () => {
            setState({ ready: false, pages: [] });

            // Load the PDF
            const loadingTask = pdfjs.getDocument({
                data: file ? file : getPdfFromByteArray(content),
                isEvalSupported: false,
            });
            const pdf = await loadingTask.promise;

            // Fetch all pages
            const pages = [];
            for(let i = 1; i <= pdf.numPages; i++) {
                const page = await pdf.getPage(i);

                // tslint:disable-next-line: no-array-mutation
                pages.push(page);
            }

            setState({ ready: true, pages });
            onDocumentComplete && onDocumentComplete();
        };

        try {
            fetchPdf();
        } catch(ex) {
            onDocumentFailed && onDocumentFailed();
        }
    }, [file, content]);

    if(!state.ready)
        return loadingScreen || <div>Loading PDF...</div>;

    return <div>
        <div style={{ textAlign: 'right', marginBottom: '0.5em' }}>
            <a href={downloadLink} download target="_new" className="button is-light">Save / Print</a>
        </div>
        {state.pages.map((page, i) => <PdfPage key={i} page={page} scale={scale} />)}
    </div>;
};
