import * as React from 'react';
import { Link } from 'react-router-dom';

import { Section, HtmlString, Button, SectionHeader } from 'web-shared/components';

interface Props {
    heading?: string;
    text: string;
    url: string;
}
export const GetStartedPane: React.FC<Props> = ({
    heading, text, url
}) => {
    if(!url || !text)
        return null;

    return <Section id="getstarted">
        <style jsx>{`
            .row :global(p) { margin-bottom: 10px; font-size: 1.25em; }
            .row :global(a) {
                text-decoration: underline;
                text-underline-position: under;
            }
        `}</style>

        <SectionHeader tag="h1" tabIndex={0}>
            {heading || 'What are you waiting for? Get started now!'}
        </SectionHeader>
        <div className="row">
            <HtmlString text={text} style={{ color: 'var(--color-highlight)', fontWeight: 'bold' }} tag="p" />

            {url.indexOf("http") === 0 ? <a href={url}>
                <Button color="secondary" size="medium">Get Started Now!</Button>
            </a> : <Link to={url}>
                <Button color="secondary" size="medium">Get Started Now!</Button>
            </Link>}
        </div>
    </Section>;
};
