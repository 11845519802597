import { parse, parseISO, format, formatDistance, differenceInDays, startOfYear } from 'date-fns';
import numeral from 'numeral';
import moize from 'moize';

function asLocalDate(dateString: string | undefined) {
    const d = toDate(dateString);
    const ret = d?.getUTCHours() === 0
        ? new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()) : d;
    return ret;
}
export function toDate(d: string | Date | undefined) {
    if(typeof d !== 'string')
        return d;
    return d.indexOf('-') !== -1 ? parseISO(d) : parse(d, 'M/d/yyyy', new Date());
}
function formatBase(d: Date | string | undefined, fmt: string, parser?: (dateString: string | undefined) => Date | undefined) {
    if(d == undefined)
        return '';
    try {
        const date = typeof d !== 'string' ? d :
        parser ? parser(d) : new Date(d);
        return date == undefined ? '' : format(date, fmt);
    } catch(e) {
        return d.toString();
    }
}
export function formatUtcDate(d: Date | string | undefined, fmt: string = 'MM/dd/yyyy') {
    return formatBase(d, fmt);
}
export function formatUtcDateTime(d: Date | string | undefined, fmt: string = 'MM/dd/yyyy hh:mm:ss a') {
    return formatBase(d, fmt);
}
export function formatLocalDate(d: Date | string | undefined, fmt: string = 'MM/dd/yyyy') {
    return formatBase(d, fmt, asLocalDate);
}
export function formatRelativeDateTime(d: Date | string, from: Date | string = new Date(), addSuffix: boolean = true) {
    const d1 = toDate(d);
    const d2 = toDate(from);
    if(d1 == undefined || d2 == undefined)
        return '';
    return formatDistance(d1, d2, { addSuffix });
}
export function isDateValid(d: string | Date | undefined) {
    if(typeof (d) === 'string') {
        const parts = d.split('/');
        if(parts.length == 3 && parts[2].length !== 4)
            return false;
    }
    if(d == undefined)
        return false;
    return toDate(d || '')!.toString() !== 'Invalid Date';
}
export function formatYesNo(v: boolean | undefined | null): '' | 'Yes' | 'No' {
    return v === true ? 'Yes' : v === false ? 'No' : '';
}

export const daysElapsedInYear = moize((d: string | Date | undefined) => {
    const date = toDate(d);
    if(date == undefined)
        return 0;
    return differenceInDays(date, startOfYear(date));
});

// Polyfill from MDN
export function repeatString(s: string, repeatCount: number) {
    if(s == null) {
        throw new TypeError('can\'t convert ' + s + ' to object');
    }
    if(repeatCount === 0)
        return '';

    let str = '' + s;

    // To convert string to integer.
    let count = +repeatCount;
    if(count !== count) {
        count = 0;
    }
    if(count < 0) {
        throw new RangeError('repeat count must be non-negative');
    }
    if(count === Infinity) {
        throw new RangeError('repeat count must be less than infinity');
    }
    count = Math.floor(count);
    if(str.length === 0 || count === 0) {
        return '';
    }

    // Ensuring count is a 31-bit integer allows us to heavily optimize the
    // main part. But anyway, most current (August 2014) browsers can't handle
    // strings 1 << 28 chars or longer, so:
    if(str.length * count >= 1 << 28) {
        throw new RangeError('repeat count must not overflow maximum string size');
    }
    const maxCount = str.length * count;
    count = Math.floor(Math.log(count) / Math.log(2));
    while(count) {
        str += str;
        count--;
    }
    str += str.substring(0, maxCount - str.length);
    return str;
}

/**
 * Format a number as money
 * @param {number} value The value to format.
 * @param {boolean} decimals Round the value to the specified decimal place.
 * @param {boolean} hideZeroDecimal Don't show the decimal separator and cent value when 0.
 */
export function formatMoney(value: number | undefined, decimals: number = 2, hideZeroDecimal?: boolean) {
    return '$' + formatNumber(value, decimals, hideZeroDecimal);
}

export function formatNumberSuffix(value: number | undefined, decimals: number = 2, hideZeroDecimal?: boolean) {
    return formatNumber(value, decimals, hideZeroDecimal, true);
}

/**
 * Format a number
 * @param {number} value The value to format.
 * @param {boolean} decimals Round the value to the specified decimal place.
 * @param {boolean} hideZeroDecimal Don't show the decimal separator and cent value when 0.
 */
export function formatNumber(value: number | string | undefined, decimals: number = 2, hideZeroDecimal: boolean = true, addSuffix?: boolean) {
    if(value === undefined)
        return '';
    const num = typeof (value) === 'number' ? value :
        Number(String(value).replace(/[^\d\-\.]/g, ''));
    return numeral(num).format('0,0' + getDecimalFormat(decimals, hideZeroDecimal) + (addSuffix ? 'o' : ''));
}
function getDecimalFormat(decimals: number, hideZeroDecimal?: boolean) {
    if(decimals <= 0) return '';
    const decimalFormat = `${repeatString('0', decimals)}`;
    return hideZeroDecimal ? `.[${decimalFormat}]` : `.${decimalFormat}`;
}
