import * as React from 'react';
import equals from 'fast-deep-equal';

export function useEffectDeep(fn: React.EffectCallback, deps: React.DependencyList) {
    const isFirst = React.useRef(true);
    const prevDeps = React.useRef(deps);

    React.useEffect(() => {
        const isSame = prevDeps.current.every((obj, index) => equals(obj, deps[index]));

        if(isFirst.current || !isSame) {
            fn();
        }

        isFirst.current = false;
        prevDeps.current = deps;
    }, deps);
}
