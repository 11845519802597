import * as React from 'react';

import { Section, SectionHeader } from 'web-shared/components';
import { TestimonyItem } from './testimonyItem';
import { formatUtcDate } from 'web-shared/lib';

interface Props {
    testimonies: api.SiteConfigTestimony[];
}
export const TestimonyPane: React.FC<Props> = ({ testimonies }) => {

    // Dont show if there is nothing to show
    if(testimonies.length === 0)
        return null;

    return <Section id="testimonies" color="primary">
        <SectionHeader tag="h1" tabIndex={0}>
            What others are saying...
        </SectionHeader>

        <div className="columns" style={{ marginTop: 20 }}>
            {testimonies.map((t, i) => <TestimonyItem key={i} name={t.customerName} date={formatUtcDate(t.testimonyDate)}>
                {t.text}
            </TestimonyItem>)}
        </div>
    </Section>;
};
