import * as React from 'react';
import styled from 'styled-components';
import { useTable, useSortBy, useGlobalFilter, Column, SortingRule } from 'react-table';
import { classNames } from 'web-shared/lib';

const Styles = styled.div`
    .table.sticky th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: #fff;
        vertical-align: bottom;
    }
    th.indicator { text-align: center; }
    td.indicator { border-left: 1px solid #dbdbdb; text-align: center; }
    .fa-sort-desc, .fa-sort-asc { padding-left: 0.5em; font-size: 1.25em; }
`;

function saveSort(id: string, sortRule: SortingRule<any>) {
	if('localStorage' in window)
		localStorage.setItem(`table-sort-${id}`, JSON.stringify(sortRule));
}
function loadSort(id: string): SortingRule<any> {
	try {
		if('localStorage' in window)
			return JSON.parse(localStorage.getItem(`table-sort-${id}`) || '');
	} catch { }
	return { id: '', desc: false };
}

interface Props<D extends object = {}> {
	id: string;
	columns: Column<D>[];
	data: D[];
	search?: string;
	narrow?: boolean;
	sticky?: boolean;
	additionalState?: object;
}
export function Table<D extends object = {}>({ id, columns, data, search, narrow, sticky, additionalState }: Props<D>) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		setGlobalFilter,
		state,
	} = useTable<D>({
		columns,
		data,
		autoResetSortBy: false,
		autoResetGlobalFilter: false,
		initialState: {
			sortBy: [loadSort(id)],
			...(additionalState || {}),
		},
	}, useGlobalFilter, useSortBy);

	React.useEffect(() => {
		setGlobalFilter(search);
	}, [search]);

	// Save sort changes
	React.useEffect(() => {
		saveSort(id, state.sortBy[0]);
	}, [state.sortBy]);

	return <Styles>
		<table className={classNames('table is-fullwidth', { 'is-narrow': narrow, sticky })} {...getTableProps()}>
			<thead>
				{headerGroups.map(headerGroup => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps({ className: column.className, title: column.title, ...column.getSortByToggleProps() })}>
								{column.render('Header')}
								<span>
									{column.isSorted ? column.isSortedDesc
										? <span className="fa fa-sort-desc" />
										: <span className="fa fa-sort-asc" /> : ''}
								</span>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map(row => {
					prepareRow(row)
					return <tr {...row.getRowProps()}>
						{row.cells.map(cell => {
							return <td {...cell.getCellProps({ className: cell.column.className })}>
								{cell.render('Cell')}
							</td>
						})}
					</tr>
				})}
			</tbody>
		</table>
	</Styles>;
};
