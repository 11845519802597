import * as React from 'react';
import { UnknownLink } from './unknownLink';
import styled from 'styled-components';
import { RelativeTime } from 'web-shared/components';
import { auth } from 'web-shared/store';

const StyledListItem = styled.li`
    & a { display: flex; }
    & .user-info { text-align: right; font-size: 0.9em; line-height: 1.2em; }
    & .fa-user { display: inline-block; margin-left: 10px; font-size: 2.5em; }
    & .expires { font-size: 0.8em; }
    @media(max-width: 768px) {
        & a { flex-direction: row-reverse; justify-content: flex-end; }
        & .user-info { text-align: left; }
        & .fa-user { display: inline-block; margin-left: 0; margin-right: 10px; font-size: 2.5em; }
    }
`;

export const AccountLink: React.FC<{ name: string, email: string }> = function ({ name, email }) {
    const expireDate = auth.useState(s => s.expires);
    return <StyledListItem title="Your Profile" className="navbar-item">
        <UnknownLink url="/user">
            <div className="user-info">
                <div>{name}</div>
                <div>{email}</div>
                {expireDate ? <div className="expires">(session expires <RelativeTime utcTime={new Date(expireDate)} />)</div> : null}
            </div>
            <span className="fa fa-user" />
        </UnknownLink>
    </StyledListItem>;
};
