import Axios, { CancelTokenSource } from 'axios';
import { SimpleEventDispatcher } from 'ste-simple-events';

import { get, postJson } from "web-shared/lib";

type NegotiateResponse = {
	ConnectionId: string;
	ConnectionTimeout: number;
	ConnectionToken: string;
	DisconnectTimeout: number;
	KeepAliveTimeout: number;
	ProtocolVersion: string;
	TransportConnectTimeout: number;
	Url: string;
	RedirectUrl?: string;
};
type KeepAliveData = {
	monitoring?: boolean;
	activated?: boolean;
	reconnectKeepAliveUpdate?: () => void,
	timeout?: number;
	timeoutWarning?: number;
	userNotified?: boolean;
}
type ResponseDataMin = {
	C?: string;
	M?: string[];
	S?: boolean;
	T?: boolean;
	G?: string;
	E?: string;
}
type ResponseData = {
	MessageId?: string;
	Messages?: string[];
	Initialized?: boolean;
	ShouldReconnect?: boolean;
	GroupsToken?: string;
	Error?: string;
}

type ConnectionState = 'connecting' | 'connected' | 'reconnecting' | 'disconnected';

// const defaultContentType = 'application/x-www-form-urlencoded; charset=UTF-8';
const keepAliveWarnAt = 2 / 3; // Warn user of slow connection if we breach the X% mark of the keep alive timeout
const pingInterval = 300000;

function stringifySend(message: any) {
	if(typeof (message) === "string" || typeof (message) === "undefined" || message === null)
		return message;
	return JSON.stringify(message);
}

export class SignalR {
	version = "2.4.3";
	url: string = '';
	negotiateCancelSource = Axios.CancelToken.source();
	startCancelSource = Axios.CancelToken.source();
	beatHandle = 0;
	transportTimeoutHandle = 0;
	transportConnectTimeout = 0;
	totalTransportConnectTimeout = 0;
	reconnectTimeout = 0;
	pingIntervalId = 0;
	beatInterval = 5000;
	keepAliveData: KeepAliveData = {};
	state: ConnectionState = 'disconnected';
	id: string = '';
	token: string = '';
	disconnectTimeout = 30000; // This should be set by the server in response to the negotiate request (30s default)
	reconnectWindow = 30000; // This should be set by the server in response to the negotiate request
	lastMessageAt = new Date().getTime();
	lastActiveAt = new Date().getTime();
	configuredStopReconnectingTimeout = false;
	socket: WebSocket | undefined = undefined;
	appRelativeUrl: string = '';
	startRequested = false;
	startCompleted = false;
	connectionStopped = false;
	redirectQs = '';
	qs = '';
	messageId = '';
	webSocketServerUrl = '';

	buffer: string[] = [];

	drainBuffer() {
		// Ensure that the connection is connected when we drain (do not want to drain while a connection is not active)
		if(this.state === 'connected') {
			while(this.buffer.length > 0) {
				this.onReceivedEvent.dispatch(this.buffer.shift() || '');
			}
		}
	}
	clearBuffer() { this.buffer = []; }
	tryBuffer(message: string) {
		if(this.state === 'connecting') {
			this.buffer.push(message);
			return true;
		}

		return false;
	};

	private onStartEvent = new SimpleEventDispatcher<void>();
	private onStartingEvent = new SimpleEventDispatcher<void>();
	private onReceivedEvent = new SimpleEventDispatcher<string>();
	private onErrorEvent = new SimpleEventDispatcher<string>();
	private onConnectionSlowEvent = new SimpleEventDispatcher<void>();
	private onReconnectingEvent = new SimpleEventDispatcher<void>();
	private onReconnectEvent = new SimpleEventDispatcher<void>();
	private onStateChangedEvent = new SimpleEventDispatcher<{ oldState: ConnectionState, newState: ConnectionState }>();
	private onDisconnectEvent = new SimpleEventDispatcher<void>();

	public get onStart() { return this.onStartEvent.asEvent(); }
	public get onStarting() { return this.onStartingEvent.asEvent(); }
	public get onReceived() { return this.onReceivedEvent.asEvent(); }
	public get onError() { return this.onErrorEvent.asEvent(); }
	public get onConnectionSlow() { return this.onConnectionSlowEvent.asEvent(); }
	public get onReconnecting() { return this.onReconnectingEvent.asEvent(); }
	public get onReconnect() { return this.onReconnectEvent.asEvent(); }
	public get onStateChanged() { return this.onStateChangedEvent.asEvent(); }
	public get onDisconnect() { return this.onDisconnectEvent.asEvent(); }

	constructor(url: string) {
		this.url = url;
	}

	changeState(expectedState: ConnectionState, newState: ConnectionState) {
		if(expectedState === this.state) {
			this.state = newState;

			this.onStateChangedEvent.dispatch({ oldState: expectedState, newState });
			return true;
		}

		return false;
	}

	configurePingInterval() {
		if(!this.pingIntervalId && pingInterval) {
			this.pingIntervalId = window.setInterval(() => {
				this.pingServer();
			}, pingInterval);
		}
	}

	async pingServer() {
		let url = `${this.url}/ping`;
		url = this.addQs(url, this.qs);

		const pingResponse = await get<{ Response: string }>(url);

		if(pingResponse.isError) {
			this.onErrorEvent.dispatch(pingResponse.error);
			return false;
		}

		if(pingResponse.payload?.Response === 'pong')
			return true;

		this.onErrorEvent.dispatch(`Ping response was unexpected: ${pingResponse.payload?.Response}`);
		return false;
	}

	async start(reconnecting: boolean) {
		// Begin with the /negotiate request to the server to get a connection token
		const res = await this.negotiate(this.url, this.negotiateCancelSource);
		this.id = res.ConnectionId;
		this.token = res.ConnectionToken;

		if(res.KeepAliveTimeout) {
			this.keepAliveData.activated = true;

			// Timeout to designate when to force the connection into reconnecting converted to milliseconds
			this.keepAliveData.timeout = res.KeepAliveTimeout * 1000;

			// Timeout to designate when to warn the developer that the connection may be dead or is not responding.
			this.keepAliveData.timeoutWarning = this.keepAliveData.timeout * keepAliveWarnAt;

			// Instantiate the frequency in which we check the keep alive.  It must be short in order to not miss/pick up any changes
			this.beatInterval = (this.keepAliveData.timeout - this.keepAliveData.timeoutWarning) / 3;
		} else {
			this.keepAliveData.activated = false;
		}

		this.appRelativeUrl = res.Url;

		// Once the server has labeled the PersistentConnection as Disconnected, we should stop attempting to reconnect
		// after res.DisconnectTimeout seconds.
		this.disconnectTimeout = res.DisconnectTimeout * 1000; // in ms

		// Add the TransportConnectTimeout from the response to the transportConnectTimeout from the client to calculate the total timeout
		this.totalTransportConnectTimeout = this.transportConnectTimeout + res.TransportConnectTimeout * 1000;

		this.reconnectWindow = res.DisconnectTimeout + (this.keepAliveData.timeout || 0);

		// Start the web socket connection

		if(this.startRequested || this.connectionStopped) {
			log("WARNING! webSockets transport cannot be started. Initialization ongoing or completed.");
			return;
		}

		log("webSockets transport starting.");

		let failCalled = false;
		const onFailed = (error: string | undefined) => {
			// Don't allow the same transport to cause onFallback to be called twice
			if(!failCalled) {
				failCalled = true;
				this.transportFailed(error);
			}

			// Returns true if the transport should stop;
			// false if it should attempt to reconnect
			return !this.startCompleted || this.connectionStopped;
		}
		this.transportTimeoutHandle = window.setTimeout(() => {
			if(!failCalled) {
				failCalled = true;
				log("webSockets transport timed out when trying to connect.");
				this.transportFailed(undefined);
			}
		}, this.totalTransportConnectTimeout);


		// ws: /connect
		let opened = false;

		if(!window.WebSocket) {
			onFailed('No WebSocket support on this browser.');
			return;
		}

		if(!this.socket) {
			const parser = window.document.createElement("a");
			parser.href = this.url;

			const wsProtocol = parser.protocol === "https:" ? "wss://" : "ws://";
			let url = this.webSocketServerUrl ? this.webSocketServerUrl : wsProtocol + parser.host;

			url += this.getUrl(reconnecting);

			log(`Connecting to websocket endpoint '${url}'.`);
			this.socket = new window.WebSocket(url);

			this.socket.onopen = () => {
				opened = true;
				log("Websocket opened.");

				this.clearReconnectTimeout();

				if(this.changeState('reconnecting', 'connected') === true)
					this.onReconnectEvent.dispatch();
			};

			const that = this;
			this.socket.onclose = function(event: CloseEvent) {
				let error;

				// Only handle a socket close if the close is from the current socket.
				// Sometimes on disconnect the server will push down an onclose event
				// to an expired socket.

				if(this === that.socket) {
					if(opened && typeof event.wasClean !== "undefined" && event.wasClean === false) {
						// Ideally this would use the websocket.onerror handler (rather than checking wasClean in onclose) but
						// I found in some circumstances Chrome won't call onerror. This implementation seems to work on all browsers.
						error = 'WebSocket closed.';

						log(`Unclean disconnect from websocket: ${event.reason || "[no reason given]."}`);
					} else {
						log("Websocket closed.");
					}

					if(!onFailed || !onFailed(error)) {
						if(error)
							that.onErrorEvent.dispatch(error);

						that.reconnect();
					}
				}
			};

			this.socket.onmessage = function (event) {
				var data;

				try {
					data = event.data ? JSON.parse(event.data) : event.data;
				}
				catch(error) {
					that.onErrorEvent.dispatch(String(error));
					return;
				}

				if(data) {
					that.processMessages(data, reconnecting, () => {
						if(!failCalled) {
							that.initReceived();
						}
					});
				}
			};
		}
	}

	getUrl(reconnecting: boolean, ajaxPost?: boolean) {
		let url = this.appRelativeUrl;
		let qs = "transport=webSockets";

		// if(!ajaxPost && connection.groupsToken) {
		// 	qs += "&groupsToken=" + window.encodeURIComponent(connection.groupsToken);
		// }

		if(!reconnecting) {
			url += "/connect";
		} else {
			url += "/reconnect";

			if(!ajaxPost && this.messageId) {
				qs += `&messageId=${window.encodeURIComponent(this.messageId)}`;
			}
		}
		url += `?${qs}`;
		url = this.prepareQueryString(url);

		if(!ajaxPost) {
			url += `&tid=${Math.floor(Math.random() * 11)}`;
		}

		return url;
	}

	async initReceived() {
		if(this.startRequested) {
			log("WARNING! The client received multiple init messages.");
			return;
		}

		if(this.connectionStopped)
			return;

		this.startRequested = true;
		window.clearTimeout(this.transportTimeoutHandle);

		log("webSockets transport connected. Initiating start request.");

		const startResponse = await get<{ Response: string }>(this.getAjaxUrl("/start"), this.startCancelSource?.token, {
			skipAuthorization: true,
		});

		if(startResponse.isError) {
			log("The start request failed. Stopping the connection.");
			this.onErrorEvent.dispatch(`Invalid start response: '${startResponse.error}'. Stopping the connection.`)
			this.stop();
			return;
		}
		if(startResponse.payload.Response !== 'started') {
			log("The start request failed. Stopping the connection.");
			this.onErrorEvent.dispatch(`Invalid start response: '${startResponse.payload.Response}'. Stopping the connection.`)
			this.stop();
			return;
		}

		this.startCompleted = true;

		log("The start request succeeded. Transitioning to the connected state.");

		this.monitorKeepAlive();

		if(this.keepAliveData.activated)
			this.startHeartbeat();

		// Used to ensure low activity clients maintain their authentication.
		// Must be configured once a transport has been decided to perform valid ping requests.
		this.configurePingInterval();

		if(!this.changeState('connecting', 'connected'))
			log("WARNING! The connection was not in the connecting state.");

		// Drain any incoming buffered messages (messages that came in prior to connect)
		this.drainBuffer();

		this.onStartEvent.dispatch();

		// wire the stop handler for when the user leaves the page
		addEventListener("unload", () => {
			log("Window unloading, stopping the connection.");
			this.stop();
		});
	}

	triggerReceived(data: string) {
		if(!this.tryBuffer(data)) {
			this.onReceivedEvent.dispatch(data);
		}
	}

	processMessages(minData: ResponseDataMin, reconnecting: boolean, onInitialized: Function) {
		// Update the last message time stamp
		this.markLastMessage();

		if(minData) {
			// This is a message send directly to the client
			const data = maximizePersistentResponse(minData);

			if(data.Error) {
				// This is a global error, stop the connection.
				log(`Received an error message from the server: ${data.Error}`);
				this.onErrorEvent.dispatch(data.Error);
				this.stop(false);
				return;
			}

			if(data.MessageId) {
				this.messageId = data.MessageId;
			}

			if(data.Messages) {
				data.Messages.forEach(m => {
					this.triggerReceived(m);
				});

				this.tryInitialize(data, reconnecting, onInitialized);
			}
		}
	}

	tryInitialize(persistentResponse: ResponseData, reconnecting: boolean, onInitialized: Function) {
		if(persistentResponse.Initialized && onInitialized && !reconnecting) {
			onInitialized();
		} else if(persistentResponse.Initialized) {
			log("WARNING! The client received an init message after reconnecting.");
		}
	}

	transportFailed(error: string | undefined) {
		if(this.connectionStopped)
			return;

		window.clearTimeout(this.transportTimeoutHandle);

		if(!this.startRequested) {
			this.stop();

			log("WebSockets transport failed to connect.");
		} else if(!this.startCompleted) {
			// Do not attempt to fall back if a start request is ongoing during a transport failure.
			// Instead, trigger an error and stop the connection.
			const wrappedError = `Error during start request. Stopping the connection. ${error}`;

			log("WebSockets transport failed during the start request. Stopping the connection.");
			this.onErrorEvent.dispatch(wrappedError);

			this.stop();
		} else {
			// The start request has completed, but the connection has not stopped.
			// No need to do anything here. The transport should attempt its normal reconnect logic.
		}
	}

	async abort() {
		const url = this.getAjaxUrl("/abort");
		await postJson(url);
		log("Fired ajax abort");
	}

	getAjaxUrl(path: string) { return this.prepareQueryString(`${this.url + path}?transport=webSockets`); }

	prepareQueryString(url: string) {
		// Use addQs to start since it handles the ?/& prefix for us
		let preparedUrl = this.addQs(url, "clientProtocol=2.1");

		if(typeof (this.redirectQs) === "string") {
			// Add the redirect-specified query string params if any
			preparedUrl = this.addQs(preparedUrl, this.redirectQs);
		} else {
			// Otherwise, add the user-specified query string params if any
			preparedUrl = this.addQs(preparedUrl, this.qs);
		}

		if(this.token) {
			preparedUrl += "&connectionToken=" + window.encodeURIComponent(this.token);
		}

		return preparedUrl + `&_=${Date.now()}`;
	}

	addQs(url: string, qs: string) {
		let appender = url.indexOf("?") !== -1 ? "&" : "?";

		if(!qs)
			return url;

		if(typeof (qs) === "string") {
			const firstChar = qs.charAt(0);

			if(firstChar === "?" || firstChar === "&") {
				appender = "";
			}

			return url + appender + qs;
		}

		throw new Error("Query string property must be a string.");
	}

	stop(notifyServer: boolean = true) {
		if(this.state === 'disconnected')
			return;

		log("Stopping connection.");

		// Clear this no matter what
		window.clearTimeout(this.beatHandle);
		window.clearInterval(this.pingIntervalId);

		this.connectionStopped = true;
		window.clearTimeout(this.transportTimeoutHandle);
		this.startCancelSource?.cancel();

		// Don't trigger a reconnect after stopping
		this.clearReconnectTimeout();

		if(this.socket) {
			log("Closing the Websocket.");
			this.socket.close();
			this.socket = undefined;
		}

		if(notifyServer !== false) {
			this.abort();
		}

		this.stopMonitoringKeepAlive();
		this.negotiateCancelSource?.cancel();

		this.messageId = '';
		this.id = '';
		this.pingIntervalId = 0;
		this.lastMessageAt = 0;
		this.lastActiveAt = 0;
		this.redirectQs = '';

		// Clear out our message buffer
		this.clearBuffer();

		// Clean up this event
		this.onStartEvent.clear();

		// Trigger the disconnect event
		this.changeState(this.state, 'disconnected');
		this.onDisconnectEvent.clear();
	}

	monitorKeepAlive() {
		// If we haven't initiated the keep alive timeouts then we need to
		if(!this.keepAliveData.monitoring) {
			this.keepAliveData.monitoring = true;

			this.markLastMessage();

			// Save the function so we can unbind it on stop
			this.keepAliveData.reconnectKeepAliveUpdate = () => {
				// Mark a new message so that keep alive doesn't time out connections
				this.markLastMessage();
			};

			// Update Keep alive on reconnect
			this.onReconnectEvent.sub(this.keepAliveData.reconnectKeepAliveUpdate);

			log(`Now monitoring keep alive with a warning timeout of ${this.keepAliveData.timeoutWarning}, keep alive timeout of ${this.keepAliveData.timeout} and disconnecting timeout of ${this.disconnectTimeout}`);
		} else {
			log("Tried to monitor keep alive but it's already being monitored.");
		}
	}

	stopMonitoringKeepAlive() {
		// Only attempt to stop the keep alive monitoring if its being monitored
		if(this.keepAliveData.monitoring) {
			// Stop monitoring
			this.keepAliveData.monitoring = false;

			// Remove the updateKeepAlive function from the reconnect event
			this.onReconnectEvent.clear();

			// Clear all the keep alive data
			this.keepAliveData = {};
			log("Stopping the monitoring of the keep alive.");
		}
	}

	reconnect() {
		// We should only set a reconnectTimeout if we are currently connected and a reconnectTimeout isn't already set.
		if((this.state === 'connected' || this.state === 'reconnecting') && !this.reconnectTimeout) {
			// Need to verify before the setTimeout occurs because an application sleep could occur during the setTimeout duration.
			if(!this.verifyLastActive())
				return;

			this.reconnectTimeout = window.setTimeout(() => {
				if(!this.verifyLastActive())
					return;

				this.stop();

				if(this.ensureReconnectingState()) {
					log("webSockets reconnecting.");
					this.start(true);
				}
			}, 2000);
		}
	}

	ensureReconnectingState() {
		if(this.changeState('connected', 'reconnecting') === true)
			this.onReconnectingEvent.dispatch();
		return this.state === 'reconnecting';
	}

	clearReconnectTimeout() {
		if(this.reconnectTimeout) {
			window.clearTimeout(this.reconnectTimeout);
			this.reconnectTimeout = 0;
		}
	}

	send(data: any) {
		if(this.state === 'disconnected') {
			// Connection hasn't been started yet
			throw new Error('SignalR: Connection must be started before data can be sent. Call .start() before .send()');
		}

		if(this.state === 'connecting') {
			// Connection hasn't been started yet
			throw new Error('SignalR: Connection has not been fully initialized. Use .start().done() or .start().fail() to run logic after the connection has started.');
		}

		const payload = stringifySend(data);

		try {
			this.socket?.send(payload);
		} catch(ex) {
			this.onErrorEvent.dispatch('The Web Socket transport is in an invalid state, transitioning into reconnecting.');
		}
	}

	startHeartbeat() {
		this.lastActiveAt = new Date().getTime();
		this.beat();
	}

	markLastMessage() {
		this.lastMessageAt = new Date().getTime();
		this.lastActiveAt = this.lastMessageAt;
	}

	markActive() {
		if(this.verifyLastActive()) {
			this.lastActiveAt = new Date().getTime();
			return true;
		}

		return false;
	}

	verifyLastActive() {
		// If there is no keep alive configured, we cannot assume that timer callbacks will
		// run frequently enough to keep lastActiveAt updated.
		// https://github.com/SignalR/SignalR/issues/4536
		if(!this.keepAliveData.activated ||
			new Date().getTime() - this.lastActiveAt < this.reconnectWindow) {
			return true;
		}

		const message = `The client has been inactive since ${new Date(this.lastActiveAt)} and it has exceeded the inactivity timeout of ${this.reconnectWindow} ms. Stopping the connection.`;
		log(message);

		this.onErrorEvent.dispatch(message);
		this.stop(false);
		return false;
	}

	beat() {
		if(this.keepAliveData.monitoring)
			this.checkIfAlive();

		// Ensure that we successfully marked active before continuing the heartbeat.
		if(this.markActive()) {
			this.beatHandle = window.setTimeout(() => {
				this.beat();
			}, this.beatInterval);
		}
	}

	checkIfAlive() {
		// Only check if we're connected
		if(this.state === 'connected') {
			const timeElapsed = new Date().getTime() - this.lastMessageAt;

			// Check if the keep alive has completely timed out
			if(timeElapsed >= this.keepAliveData.timeout!) {
				log("Keep alive timed out.  Notifying transport that connection has been lost.");
				this.reconnect();
			} else if(timeElapsed >= this.keepAliveData.timeoutWarning!) {
				// This is to assure that the user only gets a single warning
				if(!this.keepAliveData.userNotified) {
					log("Keep alive has been missed, connection may be dead/slow.");
					this.onConnectionSlowEvent.dispatch();
					this.keepAliveData.userNotified = true;
				}
			} else {
				this.keepAliveData.userNotified = false;
			}
		}
	}

	configureStopReconnectingTimeout() {
		let stopReconnectingTimeout: number;

		// Check if this connection has already been configured to stop reconnecting after a specified timeout.
		// Without this check if a connection is stopped then started events will be bound multiple times.
		if(!this.configuredStopReconnectingTimeout) {
			const onReconnectTimeout = () => {
				const message = `Couldn't reconnect within the configured timeout of ${this.disconnectTimeout} ms, disconnecting.`;
				log(message);
				this.onErrorEvent.dispatch(message);
				this.stop(false);
			};

			this.onReconnecting.sub(() => {
				// Guard against state changing in a previous user defined even handler
				if(this.state === 'reconnecting')
					stopReconnectingTimeout = window.setTimeout(() => { onReconnectTimeout(); }, this.disconnectTimeout);
			});

			this.onStateChanged.sub((data) => {
				if(data.oldState === 'reconnecting') {
					// Clear the pending reconnect timeout check
					window.clearTimeout(stopReconnectingTimeout);
				}
			});

			this.configuredStopReconnectingTimeout = true;
		}
	}

	async negotiate(url: string, cancelToken: CancelTokenSource | undefined) {
		const response = await get<NegotiateResponse>(this.prepareQueryString(`${url}/negotiate`), cancelToken?.token, {
			skipAuthorization: true,
		});

		if(response.isError)
			throw `Error during negotiation request: ${response.error}`;

		return response.payload;
	}

	isDisconnecting() { return this.state === 'disconnected' }
}

const LOGGING = true;
function log(msg: string, logging: boolean = LOGGING) {
	if(logging === false)
		return;

	if(typeof (window.console) === "undefined")
	return;

	const m = `[${new Date().toTimeString()}] SignalR: ${msg}`;
	if(window.console.debug) {
		window.console.debug(m);
	} else if(window.console.log) {
		// window.console.log(m);
	}
};
function maximizePersistentResponse(minPersistentResponse: ResponseDataMin): ResponseData {
	return {
		MessageId: minPersistentResponse.C,
		Messages: minPersistentResponse.M,
		Initialized: typeof (minPersistentResponse.S) !== "undefined" ? true : false,
		ShouldReconnect: typeof (minPersistentResponse.T) !== "undefined" ? true : false,
		GroupsToken: minPersistentResponse.G,
		Error: minPersistentResponse.E,
	};
}

export const wsClient = new SignalR('/api/secured/ws');
