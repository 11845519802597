import * as React from 'react';
export function useInterval(callback: () => void, delay: number | null) {
	const savedCallback = React.useRef<() => void | Promise<any>>();
	const [count, setCount] = React.useState(0);

	React.useEffect(() => {
		// Store the most recent callback fn
		savedCallback.current = callback;
	});

	React.useEffect(() => {
		async function tick() {
			// Execute the callback and wait for return;
			await savedCallback.current();

			// Increment the counter, which causes rerenders
			if(savedCallback.current) {
				setCount(count + 1);
			}
		}

		const timer = window.setTimeout(tick, delay);
		return () => {
			savedCallback.current = undefined;
			timer && clearTimeout(timer);
		};
	}, [delay, count]);
}
