import * as React from 'react';

interface Props {
    text: string;
    query?: string;
    style?: React.CSSProperties;
}
export const HighlightText: React.FC<Props> = function ({ text, query = '', style = {} }) {
    let queryStr: string | undefined = (query || '').toLowerCase().replace(/[.?*+^$[\]\\(){}|-]/g, '\\$&');
    if(queryStr === '')
        queryStr = undefined;

    const regex = new RegExp(`(?:<[^>]+>)|(${query})`, 'gi');
    const styleText = Object.keys(style).map(key => {
        const value = (style as any)[key];
        switch(key) {
            case 'backgroundColor':
                return `background-color: ${value}`;
            case 'fontWeight':
                return `font-weight: ${value}`;
            case 'fontStyle':
                return `font-style: ${value}`;
            default:
                return '';
        }
    }).join(';');

    const html = (text || '').replace(regex, a => a.startsWith('<') ? a : `<span style="${styleText}">${a}</span>`);
    return <span className="root" dangerouslySetInnerHTML={{ __html: html }}></span>;
};
