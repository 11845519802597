import * as React from 'react';

interface Props {
    active?: boolean;
    speed?: number;
    color?: string;
    height?: number;
}

export class LoadingBar extends React.Component<Props> {
    state = {
        width: 0,
        left: 0,
    };
    interval = 0;

    componentDidMount() {
        const { speed = 20 } = this.props;
        this.interval = window.setInterval(this.animate.bind(this), speed);
    }
    componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    animate() {
        const { active = false } = this.props;

        if(active !== true) {
            return false;
        }

        let { left, width } = this.state;
        if(left === 0 && width < 100) {
            width += 4;
        } else if(width <= 100 && left < 100) {
            left += 4;
            width -= 4;
        } else if(left === 100 && width === 0) {
            left = 0;
        }

        this.setState({ left: left, width: width });
    }

    render() {
        let { left, width } = this.state;
        const { active = false, height = 2, color = '#60d778' } = this.props;

        const style = {
            left: `${active ? left : 0}%`,
            width: `${active ? width : 100}%`,
            height: `${height}px`,
            backgroundColor: color,
        };
        return <React.Fragment>
            <style jsx>{`
                .loading-bar { position: relative; top: 0; right: 0; z-index: 800; }
            `}</style>

            <div className="loading-bar" style={style} />
        </React.Fragment>;
    }
}
