import * as React from 'react';

import { HtmlString, Section } from 'web-shared/components';
import { Product } from './product';

interface Props {
    features: api.LandingPageContent[];
    featureSummaryText: string;
}
export const ProductsPane: React.FC<Props> = function ({ features = [], featureSummaryText }) {

    const highlightStyle: React.CSSProperties = { color: 'var(--color-highlight)', fontWeight: 'bold' };
    const productHtml = features.map((p, i) => {
        const summary = <HtmlString text={p.summary} style={highlightStyle} tag="p" />;
        return <Product key={i} feature={p}>
            {summary}
        </Product>;
    });
    const summary = !featureSummaryText ? null :
        <div className="container">
            <div className="row center">
                <div className="col-sm-12" style={{ fontStyle: 'italic' }}>
                    <HtmlString text={featureSummaryText} />
                </div>
            </div>
        </div>;

    return <Section id="product_info">
        {summary}
        <div className="container is-fluid">
            <div className="row">
                {productHtml}
            </div>
        </div>
    </Section>;
};
