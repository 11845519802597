import * as React from 'react';

const regex = new RegExp('\<b(>[^<]*)</b>', 'gi');

function getParagraphs(text: string) {
    return (text || '').split(/(\r\n|\n)/gi);
}
function processMarkup(markupText: string, style: object) {
    // Split markup text with a regex
    return markupText.split(regex).map((p, i) => {
        // If the token starts with >, then we have a match for a <b> tag
        if(p.toLowerCase().indexOf('>') === 0)
            return <span key={i} style={style}>{p.substring(1)}</span>;
        return <span key={i} dangerouslySetInnerHTML={{ __html: p }}></span>;
    });
}

interface Props {
    text?: string;
    tag?: string;
    style?: React.CSSProperties | undefined;
}
export const HtmlString: React.FC<Props> = function ({ text, tag = 'div', style = {} }) {
    if(!text) {
        return <div></div>;
    }
    const paragraphs = getParagraphs(text);
    const results = paragraphs.map((p, i) => React.createElement(tag, { key: i }, processMarkup(p, style)));

    return <div>{results}</div>;
};
