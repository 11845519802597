import * as React from 'react';
import { classNames } from '../lib';
import styled from 'styled-components';

interface Props {
    icon?: string;
    color?: string;
    size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    center?: boolean;
    id?: string;
    direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
    tag?: 'div' | 'span' | 'h1' | 'h2' | 'h3';
    tabIndex?: number;
    style?: React.CSSProperties;
}

const StyledRoot = styled.div`
    margin: 10px 0;
    &.h1 { margin-top: 0; font-size: 3em; }
    &.h2 { margin-top: 0; font-size: 2.8em; }
    &.h3 { margin-top: 0; font-size: 2.6em; }
    &.h4 { margin-top: 0; font-size: 2.2em; }
    &.h5 { margin-top: 0; font-size: 1.8em; }
    &.h6 { margin-top: 0; font-size: 1.5em; }
    & .headerText { margin-top: 0; line-height: 1.1em; display: flex; }
    & .subText { font-size: 1.3em; margin-bottom: 10px; }
    & .icon { margin-right: 20px; font-size: 1.3em; height: 1em; width: 1em; float: left; }

    @media(max-width: 768px) {
        &.h1 { margin-top: 0; font-size: 2.5em; }
        &.h2 { margin-top: 0; font-size: 2em; }
        &.h3 { margin-top: 0; font-size: 1.6em; }
        &.h4 { margin-top: 0; font-size: 1.2em; }
        &.h5 { margin-top: 0; font-size: 1.1em; }
        &.h6 { margin-top: 0; font-size: 1em; }
    }
`;

export const SectionHeader: React.FC<Props> = props => {
    const { icon, color, size = 'h2', tag = 'div', center = false, id, direction = 'row', tabIndex, children, style } = props;

    const headerStyle: React.CSSProperties = {};
    if(center) {
        headerStyle.textAlign = 'center';
        headerStyle.justifyContent = 'center';
    }
    if(color === 'danger') {
        headerStyle.color = '#C0504D';
    }

    return React.createElement(tag, { tabIndex, style: headerStyle, id },
        <StyledRoot className={classNames(size)} style={style}>
            <style jsx>{`
                .headerText { flex-direction: ${direction}; }
            `}</style>
            <div className="headerText">
                {icon ? <div className={'icon fa fa-' + icon} /> : null}
                {children}
            </div>
        </StyledRoot>,
    );
};
