import * as React from 'react';
import { RouteChildrenProps } from 'react-router-dom';

import { Section, NonIdealState } from 'web-shared/components';

const NotFoundPage: React.FC<RouteChildrenProps> = () => {
    return <Section>
        <style jsx>{`
            .header { font-size: 40px; margin-top: 30px; }
            .text { font-size: 20px; font-style: italic; }
        `}</style>

        <NonIdealState fullPage icon="warning" title="Invalid location..."
            subTitle="The URL you used to get here was not recognized as a valid location.
                You can try returning to the home page or navigating to the correct page using the menu on the left side of the screen."
        />
    </Section>;
};

export default NotFoundPage;
