import * as React from 'react';
import { Link } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';

import { config } from 'web-frontend/lib';
import { useWindowScrolledTo, classNames } from 'web-shared/lib';
import { HeaderProps } from '..';
import { auth, ui } from 'web-shared/store';

import { AccountLink } from './accountLink';
import { UnknownLink } from './unknownLink';

const Logo: React.FC<{ name: string, src: string }> = ({ name, src }) => {
    return <React.Fragment>
        <style jsx>{`
            .brandImg { height: 40px; display: inline-block; margin-right: 10px; max-width: 90%; }
        `}</style>

        <img alt={name} src={src} className="brandImg" />
    </React.Fragment>;
};

const Menu: React.FC<{ pageLinks: JSX.Element[], authLinks: JSX.Element[] }> = ({ pageLinks, authLinks }) => {
    const [open, setOpen] = React.useState(false);

    const ref: React.MutableRefObject<HTMLDivElement | null> = React.useRef(null);
    useOnClickOutside(ref, () => setOpen(false));

    return <div ref={ref}>
        <style jsx>{`
            .menu { display: block; width: 100%; }
            @media(min-width: 50em) {
                .menu { display: none; }
            }
        `}</style>

        <li className="nav-menu" onClick={() => setOpen(s => !s)}>
            <span className="" aria-haspopup={true} aria-expanded={false}>Menu <span className={`fa fa-angle-${open ? 'up' : 'down'}`}></span></span>
        </li>
        {open ? <ul className="dropdown-menu menu" onClick={() => setOpen(false)}>
            {pageLinks.map((link, i) => <li key={i}>{link}</li>)}
            <li role="separator" className="divider"></li>
            {authLinks.map((link, i) => <li key={i}>{link}</li>)}
        </ul> : null}
    </div>;
};

export const Header: React.FC<HeaderProps> = () => {
    const { userName, name, userId } = auth.useState(s => ({
        userName: s.userName,
        name: s.name,
        userId: s.userId,
    }));
    const headerLinks = ui.useState(s => s.headerLinks);

    const scrolled = useWindowScrolledTo(200);

    // Page Links
    const pageLinks = headerLinks.map(l => <UnknownLink key={l.name} url={l.href}><a>{l.name}</a></UnknownLink>);
    const authLinks = [
        userId ? <AccountLink name={name} email={userName} /> :
            <UnknownLink url="/create"><a title="Register">Register</a></UnknownLink>,
        userId ? <UnknownLink url="/logoff"><a title="Log off">Log off</a></UnknownLink> :
            <UnknownLink url="/login"><a title="Login">Login</a></UnknownLink>,
    ];

    return <>
        <style jsx>{`
            .header-container {
                border: 0; position: fixed; top: 0; left: 0; right: 0; z-index: 99;
                transition: top .25s linear;
            }
            .header {
                position: relative;
                background-color: #fff; margin: 0 auto; max-width: 100%;
                transition: max-width .25s linear;
            }
            .header > ul { display: flex; position: relative; padding: 0 1rem; align-items: center; border-bottom: 1px solid #999; }

            .header > ul li, .header > ul :global(.nav-menu) { list-style-type: none; padding: 1rem; }
            .nav-links, .nav-auth { display: none; }

            /* Menu items */
            .header > ul li.nav-links:hover, .header > ul li.nav-auth:hover, :global(.nav-menu:hover) {
                background-color: #244062;
                background-color: var(--color-secondary);
                color: #fff; cursor: pointer;
            }
            .header > ul li:hover :global(a) {
                color: #fff;
            }
            .header > ul li :global(a) { padding: 1rem; white-space: nowrap; }

            .logos { flex: 1; }
            .brand { padding: 5px 15px; }

            .header > ul .center li { display: inline-flex; text-align: center; }

            @media(min-width: 50em) {
                .nav-links, .nav-auth { display: block; }
                .nav-links { order: 1; }
                .nav-auth { order: 3; }
                .header-container { top: 3rem; }
                .header { max-width: 50em; }
                .logos { order: 2; text-align: center; }
                .header ul { border: 1px solid #999; }
            }
            @media(min-width: 90em) {
                .header { max-width: 80em; }
            }

            .header-container.scrolled { top: 0; }
            .header-container.scrolled .header { max-width: 100%; width: 100%; }
            .header-container.scrolled .header ul { border: 0; border-bottom: 1px solid #999; }
        `}</style>

        <header className={classNames('header-container', { scrolled })}>
            <div className="header">
                <ul>
                    <li className="logos">
                        <Link to="/"><Logo name={config.site.name} src={config.site.logo} /></Link>
                        <Link to={config.client.url} target="_blank"><Logo name={config.client.name} src={config.client.logo} /></Link>
                    </li>

                    {pageLinks.map((link, i) => <li key={i} className="nav-links">{link}</li>)}
                    {authLinks.map((link, i) => <li key={i} className="nav-auth">{link}</li>)}

                    <Menu pageLinks={pageLinks} authLinks={authLinks} />
                </ul>
            </div>
        </header>
    </>;
};
