import * as React from 'react';
import classNames from 'classnames';
import { LoadingBar } from './loadingBar';
import { SectionHeader } from './sectionHeader';
import { IconNames } from './icon';

interface Props {
    title?: string;
    icon?: IconNames;
    isError?: boolean;
    fullPage?: boolean;
    children?: string;
}

export const LoadingMessage: React.FC<Props> = (props) => {
    const { isError, title, children, icon, fullPage } = props;

    const headerIcon = icon || (isError ? 'exclamation-circle' : 'clock-o');
    return <React.Fragment>
        <style jsx>{`
            .loading { max-width: 600px; margin: 100px auto 0; }
            .loading-message { font-style: italic; font-size: 1.2em; margin-top: 1em; }
            .error { color: #c0504d; font-size: 0.8em; }
            .fullPage { margin-top: calc(50vh - 200px); }
        `}</style>

        <div className={classNames('loading', { error: isError }, { fullPage} )}>
            <SectionHeader icon={headerIcon}>{title || (isError ? 'Error...' : 'Loading...')}</SectionHeader>
            <LoadingBar active={!isError} color={isError ? '#c0504d' : '#999'} />
            <div className="row">
                <div className="col-sm-12">
                    <div className="loading-message">
                        {children !== undefined ? children : (
                            isError ? '' : 'Please wait while we retrieve this information.'
                        )}
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>;
};
