type ClassValue = string | number | ClassDictionary | ClassArray | undefined | null | false;

interface ClassDictionary {
    [id: string]: boolean | undefined | null;
}

interface ClassArray extends Array<ClassValue> { }

const hasOwn = {}.hasOwnProperty;

export function classNames(...args: ClassValue[]) {
    const classes: string[] = [];

    for(let i = 0; i < args.length; i++) {
        const arg = args[i];
        if(!arg) continue;

        if(typeof arg === 'string' || typeof arg === 'number') {
            // tslint:disable-next-line: no-array-mutation
            classes.push(String(arg));
        } else if(Array.isArray(arg) && arg.length) {
            const inner = classNames.apply(null, arg as []);
            if(inner) {
                // tslint:disable-next-line: no-array-mutation
                classes.push(inner);
            }
        } else if(typeof arg === 'object') {
            for(const key in arg) {
                if(hasOwn.call(arg, key) && (arg as ClassDictionary)[key]) {
                    // tslint:disable-next-line: no-array-mutation
                    classes.push(key);
                }
            }
        }
    }

    return classes.join(' ');
}
